import { APButton, APCard, APCenter, APColumn, APExpanded, APImage, APPadding, APPalette, APRow, APScrollView, APSizedBox, APText, getIt, showErrorDialog } from "ap-components";
import React, { useEffect, useState } from "react";
import SharedPrefs from "../../Services/SharedPrefs";
import { PolicyDetails } from "./Models/PolicyDetails";
import InsHealthService from "../../Services/InsHealthService";
import { RouteComponentProps, useLocation } from "@gatsbyjs/reach-router";
import { convertSum, launchPage } from "../../Common/Utils";
import { CircularProgress } from "@mui/material";

export default function PolicyDetailsPage(props: RouteComponentProps) {
    var location = useLocation();
    const token = new URLSearchParams(location.search).get("tkn");
    const storedToken = getIt(SharedPrefs).getInsHealthToken();
    const [policyDetails, setPolicyDetails] = useState<PolicyDetails>()
    const CareRiders = [
        {
            label: 'Annual Health Check-up',
            value: 'AHCS1144',
        },

        {
            label: 'Instant Cover',
            value: 'ICS1149',
        },
        {
            label: 'Claim Shield',
            value: 'CS1154',
        }]
    if (token) {
        getIt(SharedPrefs).setInsHealthToken(token);
    }
    if (!token && storedToken) {
        getIt(SharedPrefs).setInsHealthToken(storedToken);
    }

    useEffect(() => {
        getAPIs();
    }, [token])
    function getNivaRiderLabel(riderValue: string) {
        if (riderValue.includes("PA_LIMIT")) {
            return "Personal Accident Cover"
        } else if (riderValue.includes('SafeguardOpted')) {
            return "Safeguard"
        } else if (riderValue.includes('SafeguardPlusOpted')) {
            return "Safeguard Plus"
        } else if (riderValue.includes("HCB_LIMIT")) {
            return "Hospital Cash"
        } else if (riderValue.includes("DM_RIDER_OPTED")) {
            return riderValue.split("_").length == 4 ? (riderValue.split("_")[3] == "G" ? "Disease Management - Gold" : "Disease Management - Platinum") : "Disease Management"
        }
        else if (riderValue.includes("ACUTE_CARE_OPTED")) {
            return riderValue.split("_").length == 4 ? (riderValue.split("_")[3] == "0" ? "Acute Care - Best Consult" : `Acute Care - Best Care upto ${parseInt(riderValue.split("_")[3]).currency(0)}`) : "Acute Care"
        } else {
            return ""
        }
    }
    function getCareRiderLabel(riderValue: string) {
        return CareRiders.find(element => element.value == riderValue)?.label
    }
    async function getAPIs() {
        try {
            var policyDetails = await getIt(InsHealthService).getPolicyDetails();
            setPolicyDetails(policyDetails);
        } catch (error) {
            const err = error as any
            showErrorDialog(err.message)
        }

    }
    console.log({ policyDetails })
    if (!policyDetails) {
        return <APCenter>
            <CircularProgress />
        </APCenter>
    }
    var premiumPrice = (parseFloat(policyDetails.total) - parseFloat(policyDetails.ridersAmount) + parseFloat(policyDetails.discount ?? "0"))
    if (!policyDetails.productName.toLocaleLowerCase().includes('optima')) {
        premiumPrice = premiumPrice + parseFloat(policyDetails.deductibleDiscount?.riderAmount ?? "0")
    }
    return (
        <APColumn mainAxisSize="max" style={{ padding: '16px' }}>
            <APExpanded>
                <APScrollView style={{ width: '100%' }} >
                    <APColumn>
                        <APColumn style={{ maxWidth: '500px', }} crossAxisAlignment="stretch">
                            {/* <APCenter> */}
                            <APRow mainAxisAlignment='center'>
                                <APImage width={180} height={24} src='/insurance/icon-assetplus.png'></APImage>
                            </APRow>
                            {/* </APCenter> */}
                            <APSizedBox height="16px"></APSizedBox>
                            <APCard >
                                <APRow>
                                    <APImage width={40} height={24} src={`/insurance/icon-${showProductBanner(policyDetails.productName)}.png`}></APImage>
                                    <APSizedBox width="8px"></APSizedBox>
                                    <APText variant='h3' >
                                        {
                                            getProductName(policyDetails.productName)
                                        }
                                    </APText>
                                </APRow>
                                <APSizedBox height="8px"></APSizedBox>
                                <ColumnLabelParaXSAndValueH4 label={'Proposer'} value={policyDetails.proposerName} />
                                <APSizedBox height="8px"></APSizedBox>
                                <APRow mainAxisAlignment="spaceBetween">
                                    <ColumnLabelParaXSAndValueH4 label={'Term'} value={parseInt(policyDetails.term) > 1 ? `${policyDetails.term} years` : `${policyDetails.term} year`} />
                                    <ColumnLabelParaXSAndValueH4 label={'Sum Insured'} value={convertSum(policyDetails.sumInsured) ?? ""} />
                                </APRow>
                                {
                                    policyDetails.productName.toLowerCase().includes('optima') &&
                                    <APRow mainAxisAlignment="end">
                                        {/* <ColumnLabelParaXSAndValueH4 label={''} value={`+ ${convertSum(policyDetails.sumInsured)} from Day 1`} /> */}
                                        <APColumn crossAxisAlignment="start" style={{ width: 'fit-content' }}>
                                            <APText variant="paragraph-xsmall">{""}</APText>
                                            <APSizedBox height="4px"></APSizedBox>
                                            {/* <APText color={APPalette["positive-400"]} variant="h4">{`+ ${convertSum(policyDetails.sumInsured)} from Day 1`}</APText> */}
                                        </APColumn>
                                    </APRow>
                                }

                                <APSizedBox height="16px"></APSizedBox>{
                                    policyDetails.productName.replaceAll(" ", "").toLocaleLowerCase().includes('niva') && (
                                        <>
                                            <ColumnLabelParaXSAndValueH4 label={'Plan Type'} value={showPlanType(policyDetails.planType)} />
                                            <APSizedBox height="16px"></APSizedBox>
                                        </>
                                    )
                                }
                                <APText variant="paragraph-xsmall">Members</APText>
                                {
                                    policyDetails.members.map((e) => {
                                        return (
                                            <APText variant="h4">{e.relationship}</APText>
                                        );
                                    })
                                }
                                <APSizedBox height="16px"></APSizedBox>
                                {
                                    policyDetails !== null && policyDetails !== undefined && policyDetails.selectedRiders.length > 0 &&
                                    <>
                                        <APText variant="paragraph-xsmall">Selected Riders</APText>
                                        <APSizedBox height="4px"></APSizedBox>
                                        {
                                            policyDetails.selectedRiders.map((e, key) => {
                                                return (
                                                    <>
                                                        <RowLabelParaSAndValueParaXS key={key} label={e.riderName ?? ""} value={parseFloat(e.riderAmount ?? "").currency()} />
                                                        <APSizedBox height="4px"></APSizedBox>
                                                    </>
                                                );
                                            })
                                        }
                                    </>
                                }
                                <APSizedBox height="8px" />
                                <APRow mainAxisAlignment='spaceBetween'>
                                    {policyDetails.brochure &&
                                        <APRow mainAxisSize="max">
                                            <APImage src='/insurance/icon-download.svg' />
                                            <APSizedBox width='10px' />
                                            <APText onClick={() => window.open(policyDetails.brochure, "_blank")} variant='link-medium'>Policy Brochure</APText>
                                        </APRow>}
                                    {policyDetails.wording &&
                                        <APRow mainAxisAlignment='end'>
                                            <APSizedBox width='16px' />
                                            <APImage src='/insurance/icon-download.svg' />
                                            <APSizedBox width='10px' />
                                            <APText onClick={() => window.open(policyDetails.wording, "_blank")} variant='link-medium'>Policy Wording</APText>
                                        </APRow>}
                                </APRow>
                            </APCard>
                            <APSizedBox height="16px"></APSizedBox>
                            <APCard>
                                <APText variant="paragraph-xsmall">Price breakdown</APText>
                                <APSizedBox height="4px"></APSizedBox>
                                <RowLabelParaSAndValueH4 label='Premium Price' value={premiumPrice.currency(0)} />
                                {policyDetails.ridersAmount !== '0' &&
                                    <>
                                        <APSizedBox height="4px"></APSizedBox>
                                        <RowLabelParaSAndValueH4 label='Riders' value={parseFloat(policyDetails.ridersAmount).currency()} />
                                    </>
                                }
                                {
                                    policyDetails.discount && policyDetails.discount != "0" &&
                                    <>
                                        <APSizedBox height="4px"></APSizedBox>
                                        <RowLabelParaSAndValueH4 label='Discount' value={`- ${parseFloat(policyDetails.discount).currency()}`} isDiscount={true} />
                                    </>
                                }
                                {
                                    policyDetails.deductibleDiscount && policyDetails.deductibleDiscount.riderAmount != '0' && !policyDetails.productName.toLocaleLowerCase().includes('optima') &&
                                    <>
                                        <APSizedBox height="4px"></APSizedBox>
                                        <RowLabelParaSAndValueH4 label='Deductible Discount' value={`- ${parseFloat(policyDetails.deductibleDiscount.riderAmount!).currency()}`} isDiscount={true} />
                                    </>
                                }
                                <APSizedBox height="4px"></APSizedBox>
                                <RowLabelParaSAndValueH4 label='Total' value={parseFloat(policyDetails.total).currency()} />
                            </APCard>
                            <APSizedBox height="16px"></APSizedBox>
                            {
                                policyDetails.savedTax !== undefined && policyDetails.savedTax !== '' && policyDetails.savedTax !== null &&
                                (
                                    <APRow style={{ backgroundColor: '#D6F4DE', borderRadius: '8px', padding: '4px 8px' }}>
                                        <APText color='#1F7735' variant='paragraph-small'>
                                            Save upto {parseFloat(policyDetails.savedTax).currency()} on taxes under Section 80D
                                        </APText>
                                    </APRow>
                                )
                            }
                            <APSizedBox height="16px" />
                            <APButton
                                style={{ width: '100%' }}
                                type="primary"
                                onClick={() => { //TODO add deep link to open home page of insurance
                                    launchPage('https://aj2pf.app.goo.gl/?link=http://assetplus.io/%23!/insurance&apn=io.assetplus&isi=1200170877&ibi=io.assetplus')
                                }}
                            >View More</APButton>
                        </APColumn>
                    </APColumn>
                </APScrollView>
            </APExpanded>
        </APColumn>
    );
}
function ColumnLabelParaXSAndValueH4({ key, label, value }: { key?: number, label: string, value: string }) {
    return (
        <APColumn key={key} crossAxisAlignment="start" style={{ width: 'fit-content' }}>
            <APText variant="paragraph-xsmall">{label}</APText>
            <APSizedBox height="4px"></APSizedBox>
            <APText variant="h4">{value}</APText>
        </APColumn>
    )
}
function RowLabelParaSAndValueParaXS({ key, label, value }: { key: number, label: string, value: string }) {
    return (
        <APRow key={key} mainAxisAlignment="spaceBetween">
            <APText variant="paragraph-small">{label}</APText>
            <APText variant="paragraph-xsmall">{value}</APText>
        </APRow>
    )
}
function RowLabelParaSAndValueH4({ key, label, value, isDiscount }: { key?: number, label: string, value: string, isDiscount?: boolean }) {
    return (
        <APRow key={key} mainAxisAlignment="spaceBetween">
            <APText color={isDiscount ? APPalette["positive-500"] : undefined} variant="paragraph-small">{label}</APText>
            <APText color={isDiscount ? APPalette["positive-500"] : undefined} variant="h4">{value}</APText>
        </APRow>
    )
}
function DownLoadWidget({ icon, downloadlink, name }: { icon: string, downloadlink: string, name: string }) {
    return (
        <APRow style={{ width: 'fit-content' }}>
            <APImage src={icon} />
            <APSizedBox width='10px' />
            <APText onClick={async () => {
                console.log(downloadlink)
                window.open(downloadlink, "_blank")
            }} variant='link-medium'>{name}
            </APText>
        </APRow >

    )
}
function showPlanType(type: string) {
    switch (type) {
        case 'P':
            return 'Platinum +';
        case 'B':
            return 'Bronze +';
        case 'T':
            return 'Titanium +';
        default:
            return 'Platinum +';
    }

}
export function showProductBanner(productName: string) {
    if (productName.toLowerCase().includes('niva')) {
        return 'nivabupareassure2.0';
    } else if (productName.toLowerCase().includes('optima')) {
        return 'optimasecure';
    }
    else if (productName.toLowerCase().includes('star')) {
        return 'starcomprehensive';
    } else {
        return 'caresupreme';
    }
}
export function getProductName(productName: string) {
    if (productName.toLowerCase().includes('niva')) {
        return 'Niva Bupa ReAssure 2.0';
    } else if (productName.toLowerCase().includes('optima')) {
        return "Optima Secure"
    } else {
        return "Care Supreme"
    }
}