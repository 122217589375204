import { useLocation } from "@gatsbyjs/reach-router";
import { useEffect, useState } from "react";
import { Constants } from "./ClientActivation";
import { APTypography } from "ap-components/src/utils/APTypography";
import moment from "moment";
import Activation2Model, { IncomeIndexType, MaritalStatusIndexType, OccupationIndexType, PepIndexType, UserForActivationWorkflow } from "../../Models/Activation2Model";
import MutualFundApiServices from "../../Services/MutualFundServices";
import { JourneyStepApp, APColumn, APPalette, APText, APExpanded, APFormFieldText, APFormFieldDropdown, APSizedBox, APFormFieldCheckbox, getIt, APCenter, APFormFieldImage, showLoadingDialog, showErrorDialog } from "ap-components";

type PersonalDetailsType = {
    name: string;
    placeOfBirth: string;
    maritalStatusIndex?: MaritalStatusIndexType;
    occupationIndex?: OccupationIndexType;
    incomeIndex?: IncomeIndexType;
    pepIndex?: PepIndexType;
    fatherName?: string;
    motherName?: string;
    dematEnable: boolean;
    dematDp?: 'CDSL' | 'NSDL';
    dematDpId?: string;
    dematClientId?: string;
    secondHolderPlaceOfBirth?: string;
    secondHolderMaritalStatusIndex?: MaritalStatusIndexType;
    secondHolderOccupation?: OccupationIndexType;
    secondHolderIncomeSlab?: IncomeIndexType;
    secondHolderPepIndex?: PepIndexType;
    secondHolderFatherName?: string;
    secondHolderMotherName?: string;
    fatcaCountry?: string;
    fatcaTpin?: string;
    fatcaIdType?: string;
    minorGender?: string;
    minorDob?: string
    minorPob?: string
};

export default function PersonalDetailsStepperFunction({ activationData, updateActivationData }: { activationData: Partial<Activation2Model>, updateActivationData: (activationData: Partial<Activation2Model>) => void }): JourneyStepApp {
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    const [fhPanImage, setFhPanImage] = useState<string>();
    const [shPanImage, setShPanImage] = useState<string>();

    function shouldAllowSave() {
        if (
            (activationData.taxStatus != '02' && activationData.firstHolderPanImageUploadStatus == 'YET TO UPLOAD') ||
            (activationData.taxStatus == '02' && activationData.guardianPanImageUploadStatus === 'YET TO UPLOAD') ||
            (activationData.holdingType == 'AS' && activationData.secondHolderPanImageUploadStatus === 'YET TO UPLOAD')
        ) {
            return true
        }
        return false
    }

    const [form, setForm] = useState<PersonalDetailsType>({
        name: activationData.name ?? '',
        placeOfBirth: activationData.taxStatus != '02' ? (activationData.placeOfBirth ?? '') : (activationData.guardianPlaceOfBirth ?? ''),
        maritalStatusIndex: activationData.maritalStatusIndex,
        occupationIndex: activationData.occupationIndex,
        incomeIndex: activationData.incomeIndex,
        pepIndex: activationData.pepIndex,
        fatherName: activationData.fatherName,
        motherName: activationData.motherName,
        dematEnable: activationData.dematEnable ?? false,
        dematDp: activationData.dematDp,
        dematDpId: activationData.dematDpId,
        dematClientId: activationData.dematClientId,
        secondHolderPlaceOfBirth: activationData.secondHolderPlaceOfBirth,
        secondHolderMaritalStatusIndex: activationData.secondHolderMaritalStatusIndex,
        secondHolderOccupation: activationData?.secondHolderOccupation,
        secondHolderIncomeSlab: activationData.secondHolderIncomeSlab,
        secondHolderPepIndex: activationData.secondHolderPEP,
        secondHolderFatherName: activationData.secondHolderFatherName,
        secondHolderMotherName: activationData.secondHolderMotherName,
        minorDob: activationData.dob,
        minorPob: activationData.placeOfBirth
    });

    const { taxStatus } = activationData;
    return (
        {
            title: "Personal Details",
            code: 'Personal Details',
            description: "",
            form: (
                <APColumn key={activationData.activationWorkflowProgress} style={{ color: APPalette['grey-500'] }} crossAxisAlignment='stretch' gap='8px'>
                    {!!activationData.secondHolderName && (
                        <APText style={APTypography.h4}>Holder 1: Fill details for {activationData?.name}</APText>
                    )}
                    {activationData.taxStatus == '02' && (
                        <>
                            <APText style={APTypography.h4}>Minor Details</APText>
                            <APExpanded>
                                <APFormFieldText
                                    initialValue={form.name}
                                    label='Name'
                                    keyboardType="text"
                                    onChanged={(v) => {
                                        setForm((prevForm) => ({
                                            ...prevForm,
                                            name: v
                                        }));
                                    }}
                                    validator={(v) => {
                                        if (taxStatus != '02') return null;
                                        if (!v) {
                                            return "Minor name required"
                                        };

                                        return null;
                                    }}
                                />
                            </APExpanded>
                            <APExpanded>
                                <APFormFieldDropdown
                                    initialValue={activationData.gender}
                                    items={Constants.gender}
                                    // key={activationData.maritalStatusIndex}
                                    label='Gender'
                                    onChanged={(v) => {
                                        if (!v) return
                                        setForm((prevForm) => ({
                                            ...prevForm,
                                            minorGender: v!
                                        }));
                                    }}
                                    validator={(v) => {
                                        if (taxStatus != '02') return null;

                                        if (!v) {
                                            return 'Gender required';
                                        }
                                        return null;
                                    }}
                                    style={{ height: "40px" }}
                                />
                            </APExpanded>
                            <APExpanded>
                                <APFormFieldText
                                    initialValue={moment(form.minorDob).format("YYYY-MM-DD")}
                                    label={`Date of Birth`}
                                    keyboardType="date"
                                    onChanged={(v) => {
                                        if (v) {
                                            form.minorDob = moment(v).format("DD/MM/YYYY")
                                        }
                                    }}
                                    validator={(v) => {
                                        if (taxStatus != '02') return null;

                                        if (!v)
                                            return "D.O.B. is required"
                                        if (v && moment().diff(v, 'years') < 1)
                                            return ' Invalid Date of Birth'
                                        if (!moment(v).isValid())
                                            return "Invalid Date"
                                        return null;
                                    }}
                                />
                            </APExpanded>
                            <APExpanded>
                                <APFormFieldText
                                    initialValue={form.minorPob}
                                    // key={activationData.placeOfBirth}
                                    label='Place of Birth'
                                    keyboardType="text"
                                    onChanged={(v) => {
                                        setForm((prevForm) => ({
                                            ...prevForm,
                                            minorPob: v
                                        }));
                                    }}
                                    validator={(v) => {
                                        if (taxStatus != '02') return null;

                                        if (!v) {
                                            return "Place of Birth required"
                                        };

                                        return null;
                                    }}
                                />
                            </APExpanded>
                            <APSizedBox height="16px" />
                            <APText style={APTypography.h4}>Guardian Details</APText>
                        </>
                    )}
                    {((activationData.taxStatus != '02' && activationData.firstHolderPanImageUploadStatus == 'YET TO UPLOAD') || (activationData.taxStatus == '02' && activationData.guardianPanImageUploadStatus === 'YET TO UPLOAD'))
                        && <APCenter>
                            <APFormFieldImage
                                label="PAN Card"
                                src=''
                                onChange={async (value) => {
                                    if (value) {
                                        setFhPanImage(value);
                                        try {
                                            let updatedActivationData = await showLoadingDialog(async () => await getIt(MutualFundApiServices).uploadPANImage2({ token: token!, panImageBase64: value, holder: activationData.taxStatus != '02' ? 'primary' : 'guardian' }));
                                            updateActivationData(updatedActivationData);
                                        } catch (err: any) {
                                            setFhPanImage(undefined);
                                            showErrorDialog(err.message);
                                        }
                                    }
                                }}
                                validator={(file) => {
                                    if (!file) {
                                        return "Please upload a valid image";
                                    }
                                    return null;
                                }}
                            />
                        </APCenter>
                    }
                    <APExpanded>
                        <APFormFieldText
                            initialValue={form.placeOfBirth}
                            // key={activationData.placeOfBirth}
                            label='Place of Birth'
                            keyboardType="text"
                            onChanged={(v) => {
                                setForm((prevForm) => ({
                                    ...prevForm,
                                    placeOfBirth: v
                                }));
                            }}
                            validator={(v) => {
                                if (!v) {
                                    return "Place of Birth required"
                                };

                                return null;
                            }}
                        />
                    </APExpanded>
                    {(activationData.taxStatus != '02' || (activationData.taxStatus == '02' && !activationData.guardianHasCkyc)) && <APExpanded>
                        <APFormFieldDropdown
                            initialValue={activationData.maritalStatusIndex}
                            items={Constants.maritalStatusIndices}
                            // key={activationData.maritalStatusIndex}
                            label='Marital Status'
                            onChanged={(v) => {
                                setForm((prevForm) => ({
                                    ...prevForm,
                                    maritalStatusIndex: v as MaritalStatusIndexType
                                }));
                            }}
                            validator={(v) => {
                                if (activationData.taxStatus == '02' && activationData.guardianHasCkyc) return null;
                                if (!v) {
                                    return 'Marital Status Required';
                                }
                                return null;
                            }}
                            style={{ height: "40px" }}
                        />
                    </APExpanded>}
                    <APExpanded>
                        <APFormFieldDropdown
                            initialValue={activationData.occupationIndex}
                            items={Constants.occupationIndices}
                            // key={activationData.occupationIndex}
                            label='Occupation'
                            onChanged={(v) => {
                                setForm((prevForm) => ({
                                    ...prevForm,
                                    occupationIndex: v as OccupationIndexType
                                }));
                            }}
                            validator={(v) => {
                                if (!v) {
                                    return 'Occupation Required';
                                };
                                return null;
                            }}
                            style={{ height: "40px" }}
                        />
                    </APExpanded>
                    <APExpanded>
                        <APFormFieldDropdown
                            initialValue={activationData.incomeIndex}
                            items={Constants.incomeIndices}
                            // key={activationData.incomeIndex}
                            label='Annual Income'
                            onChanged={(v) => {
                                setForm((prevForm) => ({
                                    ...prevForm,
                                    incomeIndex: v as IncomeIndexType
                                }));
                            }}
                            validator={(v) => {
                                if (!v) {
                                    return 'Annual Income Required';
                                };
                                return null;
                            }}
                            style={{ height: "40px" }}
                        />
                    </APExpanded>
                    <APExpanded>
                        <APFormFieldDropdown
                            initialValue={activationData.pepIndex}
                            items={Constants.pepIndices}
                            // key={activationData.pepIndex}
                            label='Political Exposure'
                            onChanged={(v) => {
                                setForm((prevForm) => ({
                                    ...prevForm,
                                    pepIndex: v as PepIndexType
                                }));
                            }}
                            validator={(v) => {
                                if (!v) {
                                    return 'Political exposure';
                                };
                                return null;
                            }}
                            style={{ height: "40px" }}
                        />
                    </APExpanded>
                    {((taxStatus != '02' && !activationData.firstHolderHasCkyc) || (taxStatus == '02' && !activationData.guardianHasCkyc)) && (
                        <>
                            <APExpanded>
                                <APFormFieldText
                                    label='Father Name'
                                    keyboardType="text"
                                    initialValue={form.fatherName}
                                    onChanged={(v) => {
                                        setForm((prevForm) => ({
                                            ...prevForm,
                                            fatherName: v
                                        }));
                                    }}
                                    validator={(v) => {
                                        if ((taxStatus != '02' && activationData.firstHolderHasCkyc) || (taxStatus == '02') && activationData.guardianHasCkyc) return null;

                                        if (!v) {
                                            return "Father Name required"
                                        };

                                        return null;
                                    }}
                                />
                            </APExpanded>
                            <APExpanded>
                                <APFormFieldText
                                    initialValue={activationData.motherName}
                                    label='Mother Name'
                                    keyboardType="text"
                                    onChanged={(v) => {
                                        setForm((prevForm) => ({
                                            ...prevForm,
                                            motherName: v
                                        }));
                                    }}
                                    validator={(v) => {
                                        if ((taxStatus != '02' && activationData.firstHolderHasCkyc) || (taxStatus == '02') && activationData.guardianHasCkyc) return null;

                                        if (!v) {
                                            return "Mother Name required"
                                        };

                                        return null;
                                    }}
                                />
                            </APExpanded>
                            {activationData.dematEnable && <>
                                <APFormFieldCheckbox
                                    label="Enable Demat Mode"
                                    initialValue={activationData.dematEnable} onChanged={(value) => {
                                        setForm((prevForm) => ({
                                            ...prevForm,
                                            dematEnable: value
                                        }));
                                    }} />
                                <APFormFieldDropdown<'CDSL' | 'NSDL'> label="Depository Participant (DP)"

                                    items={[{ label: "CDSL", value: "CDSL" }, { label: "NSDL", value: "NSDL" }]}
                                    initialValue={form.dematDp}
                                    validator={(v) => {
                                        if (v == null) {
                                            return "Please select DP";
                                        }
                                        return null;
                                    }}
                                    onChanged={(v) => {
                                        if (v != null)
                                            form.dematDp = v;
                                    }}
                                />

                                <APFormFieldText
                                    label="DP Id"
                                    initialValue={form.dematDpId}
                                    onChanged={(v) => {
                                        form.dematDpId = v;
                                    }}
                                    validator={(v) => {
                                        if (v.length != 8) {
                                            return "Please enter 8 digit DP Id"
                                        }
                                        return null;
                                    }}
                                />

                                <APFormFieldText
                                    label="DP Client Id"
                                    initialValue={form.dematClientId}
                                    onChanged={(v) => {
                                        form.dematClientId = v;
                                    }}
                                    validator={(v) => {
                                        if (v.length != 8) {
                                            return "Please enter 8 digit DP Client Id"
                                        }
                                        return null;
                                    }}
                                />
                            </>}
                        </>
                    )}
                    {
                        ["21", "24"].includes(activationData.taxStatus ?? '') && (
                            <>
                                <APExpanded>
                                    <APFormFieldDropdown
                                        initialValue={activationData?.foreignAddress?.fatcaCountry}
                                        items={Constants.taxCountries}
                                        label='Tax Country'
                                        onChanged={(v) => {
                                            if (!v) return
                                            setForm((prevForm) => ({
                                                ...prevForm,
                                                fatcaCountry: v
                                            }));
                                        }}
                                        validator={(v) => {
                                            if (!['21', '24'].includes(activationData.taxStatus ?? '')) return null;

                                            if (!v) {
                                                return "Tax Country required"
                                            };

                                            return null;
                                        }}
                                        style={{ height: "40px" }}
                                    />
                                </APExpanded>
                                <APExpanded>
                                    <APFormFieldDropdown
                                        initialValue={activationData?.foreignAddress?.fatcaIdType}
                                        items={Constants.taxIdTypes}
                                        label='ID Type'
                                        onChanged={(v) => {
                                            if (!v) return
                                            setForm((prevForm) => ({
                                                ...prevForm,
                                                fatcaIdType: v
                                            }));
                                        }}
                                        validator={(v) => {
                                            if (!['21', '24'].includes(activationData.taxStatus ?? '')) return null;

                                            if (!v) {
                                                return "Tax Country required"
                                            };

                                            return null;
                                        }}
                                        style={{ height: "40px" }}
                                    />
                                </APExpanded>
                                <APExpanded>
                                    <APFormFieldText
                                        initialValue={activationData?.foreignAddress?.fatcaCountry}
                                        label='Tax ID'
                                        keyboardType="text"
                                        onChanged={(v) => {
                                            setForm((prevForm) => ({
                                                ...prevForm,
                                                fatcaTpin: v
                                            }));
                                        }}
                                        validator={(v) => {
                                            if (!['21', '24'].includes(activationData.taxStatus ?? '')) return null;

                                            if (!v) {
                                                return "Tax ID required"
                                            };

                                            return null;
                                        }}
                                    />
                                </APExpanded>
                            </>
                        )
                    }
                    {activationData.holdingType == 'AS' && (<>
                        <APText style={APTypography.h4}>Holder 2: Fill details for {activationData?.secondHolderName}</APText>
                        {(activationData.holdingType == 'AS' && activationData.secondHolderPanImageUploadStatus === 'YET TO UPLOAD')
                            && <APCenter>
                                <APFormFieldImage
                                    label="PAN Card"
                                    src=''
                                    onChange={async (value) => {
                                        if (value) {
                                            try {
                                                setShPanImage(value)
                                                let updatedActivationData = await showLoadingDialog(async () => await getIt(MutualFundApiServices).uploadPANImage2({ token: token!, panImageBase64: value, holder: 'secondary' }));
                                                updateActivationData(updatedActivationData);
                                            } catch (err: any) {
                                                setShPanImage(undefined)
                                                showErrorDialog(err.message);
                                            }
                                        }
                                    }}
                                    validator={(file) => {
                                        if (!file) {
                                            return "Please upload a valid image";
                                        }
                                        return null;
                                    }}
                                />
                            </APCenter>
                        }
                        <APExpanded>
                            <APFormFieldText
                                initialValue={activationData.secondHolderPlaceOfBirth}
                                label='Place of Birth'
                                keyboardType="text"
                                onChanged={(v) => {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        secondHolderPlaceOfBirth: v
                                    }));
                                }}
                                validator={(v) => {
                                    if (activationData.holdingType == 'SI') return null;
                                    if (!v) {
                                        return "Place of Birth required"
                                    };

                                    return null;
                                }}
                            />
                        </APExpanded>
                        <APExpanded>
                            <APFormFieldDropdown
                                initialValue={activationData.secondHolderMaritalStatusIndex}
                                items={Constants.maritalStatusIndices}
                                // key={form.maritalStatusIndex}
                                label='Marital Status'
                                onChanged={(v) => {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        secondHolderMaritalStatusIndex: v as MaritalStatusIndexType
                                    }));
                                }}
                                validator={(v) => {
                                    if (activationData.holdingType == 'SI') return null;
                                    if (!v) {
                                        return 'Marital Status Required';
                                    }
                                    return null;
                                }}
                                style={{ height: "40px" }}
                            />
                        </APExpanded>
                        <APExpanded>
                            <APFormFieldDropdown
                                initialValue={activationData.secondHolderOccupation}
                                items={Constants.occupationIndices}
                                // key={form.secondHolderOccupation}
                                label='Occupation'
                                onChanged={(v) => {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        secondHolderOccupation: v as OccupationIndexType
                                    }));
                                }}
                                validator={(v) => {
                                    if (activationData.holdingType == 'SI') return null;

                                    if (!v) {
                                        return 'Occupation Required';
                                    };
                                    return null;
                                }}
                                style={{ height: "40px" }}
                            />
                        </APExpanded>
                        <APExpanded>
                            <APFormFieldDropdown
                                initialValue={activationData.secondHolderIncomeSlab}
                                items={Constants.incomeIndices}
                                // key={form.secondHolderIncomeSlab}
                                label='Annual Income'
                                onChanged={(v) => {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        secondHolderIncomeSlab: v as IncomeIndexType
                                    }));
                                }}
                                validator={(v) => {
                                    if (activationData.holdingType == 'SI') return null;

                                    if (!v) {
                                        return 'Annual Income Required';
                                    };
                                    return null;
                                }}
                                style={{ height: "40px" }}
                            />
                        </APExpanded>
                        <APExpanded>
                            <APFormFieldDropdown
                                initialValue={activationData.pepIndex}
                                items={Constants.pepIndices}
                                // key={form.secondHolderPepIndex}
                                label='Political Exposure'
                                onChanged={(v) => {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        secondHolderPepIndex: v as PepIndexType
                                    }));
                                }}
                                validator={(v) => {
                                    if (activationData.holdingType == 'SI') return null;

                                    if (!v) {
                                        return 'Political exposure';
                                    };
                                    return null;
                                }}
                                style={{ height: "40px" }}
                            />
                        </APExpanded>
                        {activationData.secondHolderHasCkyc == false && (<>
                            <APExpanded>
                                <APFormFieldText
                                    initialValue={activationData.secondHolderFatherName}
                                    label='Father Name'
                                    keyboardType="text"
                                    onChanged={(v) => {
                                        setForm((prevForm) => ({
                                            ...prevForm,
                                            secondHolderFatherName: v
                                        }));
                                    }}
                                    validator={(v) => {
                                        if (!v) {
                                            return "Father Name required"
                                        };

                                        return null;
                                    }}
                                />
                            </APExpanded>
                            <APExpanded>
                                <APFormFieldText
                                    initialValue={activationData.secondHolderMotherName}
                                    label='Mother Name'
                                    keyboardType="text"
                                    onChanged={(v) => {
                                        setForm((prevForm) => ({
                                            ...prevForm,
                                            secondHolderMotherName: v
                                        }));
                                    }}
                                    validator={(v) => {
                                        if (!v) {
                                            return "Mother Name required"
                                        };

                                        return null;
                                    }}
                                />
                            </APExpanded>
                        </>)}
                    </>)}
                </APColumn>
            ),
            onSubmit: shouldAllowSave() ? () => { throw new Error("Please Wait") } : async () => {
                const dataToSubmit: { token: string, firstHolderDetails: PersonalRequestDetails, secondHolderDetails?: PersonalRequestDetails, minorDetails?: MinorDetailsType, dematDetails?: DematDetailsType } = {
                    token: token!,
                    firstHolderDetails: {
                        placeOfBirth: form.placeOfBirth,
                        maritalStatusIndex: form.maritalStatusIndex!,
                        occupationIndex: form.occupationIndex!,
                        incomeIndex: form.incomeIndex!,
                        pepIndex: form.pepIndex!,
                    }
                };

                if (!activationData.firstHolderHasCkyc) {
                    dataToSubmit.firstHolderDetails.fatherName = form.fatherName;
                    dataToSubmit.firstHolderDetails.motherName = form.motherName;
                }

                if (['21', '24'].includes(activationData.taxStatus ?? '')) {
                    dataToSubmit.firstHolderDetails.fatcaCountry = form.fatcaCountry;
                    dataToSubmit.firstHolderDetails.fatcaIdType = form.fatcaIdType;
                    dataToSubmit.firstHolderDetails.fatcaTpin = form.fatcaTpin;
                }

                if (activationData.holdingType == 'AS') {
                    dataToSubmit.secondHolderDetails = {
                        placeOfBirth: form.secondHolderPlaceOfBirth!,
                        maritalStatusIndex: form.secondHolderMaritalStatusIndex!,
                        occupationIndex: form.secondHolderOccupation!,
                        incomeIndex: form.secondHolderIncomeSlab!,
                        pepIndex: form.secondHolderPepIndex!,
                    }

                    if (!activationData.secondHolderHasCkyc) {
                        dataToSubmit.secondHolderDetails.fatherName = form.secondHolderFatherName;
                        dataToSubmit.secondHolderDetails.motherName = form.secondHolderMotherName;
                    }
                }

                if (taxStatus == '02') {
                    dataToSubmit.minorDetails = {
                        name: form.name,
                        gender: form.minorGender ?? '',
                        dob: form.minorDob ?? '',
                        placeOfBirth: form.minorPob ?? ''
                    }
                    if (!activationData.guardianHasCkyc) {
                        dataToSubmit.firstHolderDetails.fatherName = form.fatherName;
                        dataToSubmit.firstHolderDetails.motherName = form.motherName;
                    }
                }

                const updatedActivationData = await getIt(MutualFundApiServices).savePersonalDetails(dataToSubmit);

                updateActivationData(updatedActivationData);
            }
        }
    );
}

export type PersonalRequestDetails = {
    placeOfBirth: string;
    maritalStatusIndex: MaritalStatusIndexType;
    occupationIndex: OccupationIndexType;
    incomeIndex: string;
    pepIndex: string;
    fatherName?: string;
    motherName?: string;
    guardianPlaceOfBirth?: string;
    minorGender?: string;
    minorPlaceOfBirth?: string;
    minorDOB?: string;
    minorName?: string;
    fatcaCountry?: string;
    fatcaIdType?: string;
    fatcaTpin?: string;
}


export type MinorDetailsType = {
    name: string;
    gender: string;
    dob: string;
    placeOfBirth: string;
}

export type DematDetailsType = {
    dematDp: string
    dematDpId: string
    dematClientId: string
}