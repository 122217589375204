import { useLocation } from "@gatsbyjs/reach-router";
import { APTypography } from "ap-components/src/utils/APTypography";
import Activation2Model from "../../Models/Activation2Model";
import MutualFundApiServices from "../../Services/MutualFundServices";
import { JourneySectionApp, getIt, APColumn, APSizedBox, APCard, APText, APFormFieldImage } from "ap-components";
import { useState, useEffect } from "react";

export default function PhotoStepperFunction({ activationData, updateActivationData }: { activationData: Partial<Activation2Model>, updateActivationData: (activationData: Partial<Activation2Model>) => void }): JourneySectionApp {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const [photoImage, setPhotoImage] = useState<string>(activationData.imagePhoto ?? '');
    const [secondHolderPhotoImage, setSecondHolderPhotoImage] = useState<string>(activationData.secondHolderImagePhoto ?? '');

    async function getPhotoImage(holder: 'primary' | 'secondary' | 'guardian') {
        const signatureBase64 = await getIt(MutualFundApiServices).getFile({ token: token!, holder, file: "photo" });
        if (holder != 'secondary') {
            setPhotoImage(signatureBase64);
        } else {
            setSecondHolderPhotoImage(signatureBase64);
        }
    };

    useEffect(() => {
        if (activationData.activationWorkflowProgress === 'Photo___FH' && activationData.imagePhoto) {
            getPhotoImage(activationData.taxStatus !== '02' ? 'primary' : 'guardian');
        };
        if (activationData.activationWorkflowProgress === 'Photo___SH' && activationData.secondHolderImagePhoto) {
            getPhotoImage('secondary');
        }
    }, []);

    const steps = [];

    if (!activationData.firstHolderHasCkyc) {
        steps.push({
            title: `Photo${activationData.holdingType == 'SI' ? '' : ' for Holder 1'}`,
            code: "FH",
            description: activationData.taxStatus !== '02' ? activationData.name ?? '' : activationData.guardianName ?? '',
            form: (
                <APColumn crossAxisAlignment="start">
                    <APSizedBox height='16px' />

                    <APColumn crossAxisAlignment='stretch' gap='8px'>
                        <APCard>
                            <APColumn crossAxisAlignment="start">
                                <APText style={APTypography.h4}>Please upload photo</APText>
                                <APText style={APTypography["paragraph-small"]}>• Please ensure the photo is clear, colored and taken on a light preferably white background.</APText>
                                <APText style={APTypography["paragraph-small"]}>• Photo must be without cap or goggles.</APText>
                            </APColumn>
                        </APCard>
                        {activationData.secondHolderName && (
                            <APText style={APTypography.h4}>Photo for Holder 1</APText>
                        )}
                        <APFormFieldImage
                            label="Image"
                            key={photoImage}
                            src={photoImage}
                            onChange={(src) => {
                                if (src) {
                                    setPhotoImage(src)
                                }
                            }}
                            validator={(v) => !v || v.length === 0 ? "Please select an image" : null}
                        />
                    </APColumn>
                    <APSizedBox height='16px' />
                </APColumn>
            ),
            onSubmit: async () => {
                if (!photoImage) {
                    throw new Error("Photo required");
                }
                const dataToSubmit: PhotoRequestForHolderClass = {
                    token: token!,
                    photoBase64: photoImage,
                    holder: activationData.taxStatus != '02' ? 'primary' : 'guardian'
                };

                const updatedActivationData = await getIt(MutualFundApiServices).savePhotoImageForHolder(dataToSubmit);

                updateActivationData(updatedActivationData);
            }
        })
    }

    if (activationData.holdingType == 'AS' && !activationData.secondHolderHasCkyc) {
        steps.push({
            title: "Photo for Holder 2",
            description: activationData.secondHolderName ?? '',
            code: "SH",
            form: (
                <APColumn crossAxisAlignment="start">
                    <APSizedBox height='16px' />

                    <APColumn crossAxisAlignment='stretch' gap='8px'>
                        <APCard>
                            <APColumn crossAxisAlignment="start">
                                <APText style={APTypography.h4}>Please upload photo</APText>
                                <APText style={APTypography["paragraph-small"]}>• Please ensure the photo is clear, colored and taken on a light preferably white background.</APText>
                                <APText style={APTypography["paragraph-small"]}>• Photo must be without cap or goggles.</APText>
                            </APColumn>
                        </APCard>
                        <APFormFieldImage
                            label="Image"
                            key={secondHolderPhotoImage}
                            src={secondHolderPhotoImage}
                            onChange={(src) => {
                                if (src) {
                                    setPhotoImage(src)
                                }
                            }}
                            validator={(v) => !v || v.length === 0 ? "Please select an image" : null}
                        />
                    </APColumn>
                </APColumn>
            ),
            onSubmit: async () => {
                if (!photoImage) {
                    throw new Error("Photo required");
                }
                const dataToSubmit: PhotoRequestForHolderClass = {
                    token: token!,
                    photoBase64: photoImage,
                    holder: 'secondary'
                };

                const updatedActivationData = await getIt(MutualFundApiServices).savePhotoImageForHolder(dataToSubmit);

                updateActivationData(updatedActivationData);
            }
        })
    }

    return {
        title: "Photo",
        code: "Photo",
        description: activationData.name ?? '',
        steps,
    };
};


export class PhotoRequestForHolderClass {
    token!: string;
    photoBase64!: string;
    holder!: 'primary' | 'secondary' | 'guardian'
}