import { APCard, APCenter, APColumn, APExpanded, APInkWell, APRow, APScrollView, APSizedBox, APText, getIt, handleErrorMessage, launchDialog } from 'ap-components'
import { ChevronRightRounded } from '@mui/icons-material';
import APAlertBar from 'ap-components/src/elements/APAlertBar';
import NomineeDialog from './NomineeDialog';
import { useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from '@gatsbyjs/reach-router';
import { launchPage } from '../../Common/Utils';
import { CircularProgress } from '@mui/material';
import MutualFundApiServices from '../../Services/MutualFundServices';
import NomineeDeclarationModel, { NomineeApiReq } from '../../Models/NomineeDeclarationModel';
import GuardianDialog from './GuardianDialog';

export default function NomineeRegulatoryValidation(_props: RouteComponentProps) {
    var location = useLocation();
    const token = new URLSearchParams(location.search).get("tkn");
    const [data, setData] = useState<NomineeDeclarationModel>();
    const [req, setReq] = useState<NomineeApiReq>();

    useEffect(() => {
        getDetails()
    }, [token])

    if (!token) {
        window.location.href = "/";
    }

    async function getDetails() {
        if (!token) { return }

        try {
            var res = await getIt(MutualFundApiServices).getDetails(token);
            setData(res)
            setReq({
                nominee: res.nominee?.name,
                nomineeRelation: res.nominee?.relationship,
                nomineeRelationCode: res.nominee?.relationshipCode,
                guardian: res.guardian?.name,
                guardianRelationCode: res.guardian?.relationshipCode
            })
        } catch (error) {
            handleErrorMessage(error);
        }
    }

    async function updateSelf() {
        if (!req || !token) {
            return
        }

        if (data?.nominee && (!req.nominee || !req.nomineeRelation || !req.nomineeRelationCode)) {
            return
        }

        if (data?.guardian && (!req.guardian || !req.guardianRelationCode)) {
            return
        }

        try {
            await getIt(MutualFundApiServices).updateSelfValidation(req, token);
            window.location.href = "/nominee_regulatory_validation/successPage";
        } catch (error) {
            handleErrorMessage(error)
        }
    }

    useEffect(() => {
        updateSelf()
    }, [req])

    if (!data || !req) {
        return (
            <APCenter>
                <CircularProgress />
            </APCenter>
        )
    }

    if (!token) {
        launchPage('/home')
        return <></>
    }

    return (
        <APColumn mainAxisSize='max' style={{ padding: "16px", width: "100vw" }} >
            <APExpanded>
                <APScrollView>
                    <APColumn>
                        <APColumn crossAxisAlignment='stretch' style={{ maxWidth: "min(500px, 100%)" }} gap="16px">
                            <APColumn crossAxisAlignment='stretch'>
                                <APText variant='h3'>Dear {data.name}</APText>
                                <APSizedBox height='8px' />
                                <APAlertBar
                                    style={{ alignItems: "start" }}
                                    padding="12px"
                                    severity="warning"
                                    content={
                                        <APText variant='paragraph-small'>Due to regulatory changes by SEBI, we need the following details from your end to ensure smooth transactions and withdrawals for your investments</APText>
                                    }
                                />

                            </APColumn>

                            {
                                data.nominee &&
                                <DeclarationCard
                                    content='Nominee Details'
                                    onClick={
                                        (req.nominee && req.nomineeRelationCode) ?
                                            undefined
                                            :
                                            async () => {
                                                try {
                                                    if (!token) {
                                                        throw new Error("Unauthorized Access")
                                                    }

                                                    const res = await launchDialog<NRVNomineeValidationModel>(deferred => <NomineeDialog deferred={deferred} details={data.nominee!} />)
                                                    setReq(prev => ({ ...prev, nominee: res.name, nomineeRelation: res.relationship, nomineeRelationCode: res.relationshipCode }))
                                                } catch (error) {
                                                    handleErrorMessage(error)
                                                }
                                            }
                                    }
                                />
                            }

                            {
                                data.guardian &&
                                <DeclarationCard
                                    content='Guardian Details'
                                    onClick={
                                        (req.nominee && req.nomineeRelation) ?
                                            undefined
                                            :
                                            async () => {
                                                try {
                                                    if (!token) {
                                                        throw new Error("Unauthorized Access")
                                                    }

                                                    var res = await launchDialog<NRVGuardianValidationModel>(deferred => <GuardianDialog deferred={deferred} details={data.guardian!} />)
                                                    setReq(prev => ({ ...prev, guardian: res.name, guardianRelationCode: res.relationshipCode }))
                                                } catch (error) {
                                                    handleErrorMessage(error)
                                                }
                                            }
                                    }
                                />
                            }

                        </APColumn>
                    </APColumn>
                </APScrollView>
            </APExpanded>
        </APColumn>
    )
}

function DeclarationCard({ onClick, content }: { content: string, onClick?: () => any }) {
    return (
        <APInkWell
            disabled={!onClick}
            onClick={() => {
                if (onClick) {
                    onClick()
                }
            }}
        >
            <APCard style={{ borderRadius: "8px", padding: "8px" }}>
                <APRow>
                    <APExpanded>
                        <APText>{content}</APText>
                    </APExpanded>
                    {
                        onClick ?
                            <APRow mainAxisSize='min'>
                                <APText variant='paragraph-small' colored={-1}>Add Details</APText>
                                <APSizedBox width='2px' />
                                <ChevronRightRounded />
                            </APRow>
                            :
                            <APText colored={1}>Completed</APText>
                    }
                </APRow>
            </APCard>
        </APInkWell>
    )
}

export class NRVNomineeValidationModel {
    name!: string
    relationship!: string
    relationshipCode!: string
}

export class NRVGuardianValidationModel {
    name!: string
    relationshipCode!: string
}