import { useLocation } from "@gatsbyjs/reach-router";
import { useState } from "react";
import { Constants, } from "./ClientActivation";
import { APTypography } from "ap-components/src/utils/APTypography";
import Activation2Model from "../../Models/Activation2Model";
import MutualFundApiServices from "../../Services/MutualFundServices";
import { JourneyStepApp, useAPForm, APColumn, APPalette, APText, APExpanded, APFormFieldText, APFormFieldDropdown, APRow, APInkWell, TrashIcon, APSizedBox, APButton, PlusIcon, getIt } from "ap-components";

type NomineeDetailsType = {
    nominee: string;
    nomineeRelation: string;
    nomineeShare: number;
    nominee2: string;
    nomineeRelation2: string;
    nomineeShare2: number;
    nominee3: string;
    nomineeRelation3: string;
    nomineeShare3: number;

}

export default function NomineeDetailsStepperFunction({ activationData, updateActivationData }: { activationData: Partial<Activation2Model>, updateActivationData: (activationData: Partial<Activation2Model>) => void }): JourneyStepApp {
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const [nomineeCount, setNomineeCount] = useState(1);
    const baseDetailsPageControl = useAPForm();
    const [form, setForm] = useState<NomineeDetailsType>({
        nominee: activationData.nominee ?? '',
        nomineeRelation: activationData.nomineeRelation ?? '',
        nomineeShare: activationData.nomineeShare ?? 0,
        nominee2: activationData.nominee2 ?? '',
        nomineeRelation2: activationData.nominee2Relation ?? '',
        nomineeShare2: activationData.nominee2Share ?? 0,
        nominee3: activationData.nominee3 ?? '',
        nomineeRelation3: activationData.nominee3Relation ?? '',
        nomineeShare3: activationData.nominee3Share ?? 0,
    });

    return (
        {
            title: "Nominee Details",
            description: "",
            code: 'Nominee Details',
            form: (
                <APColumn style={{ color: APPalette['grey-500'] }} crossAxisAlignment='stretch' gap='8px'>
                    <APText style={APTypography.h4}>Nominee 1</APText>
                    <APExpanded>
                        <APFormFieldText
                            initialValue={activationData.nominee}
                            keyboardType='text'
                            label="Nominee's Name"
                            onChanged={(v) => {
                                const values: Partial<NomineeDetailsType> = { nominee: v };

                                if (nomineeCount == 1) {
                                    values.nomineeShare = 100;
                                };

                                if (v) {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        ...values
                                    }));
                                }
                            }}
                            validator={(v) => {
                                if (!v.length) {
                                    return 'Nominee name required';
                                }
                                return null;
                            }}
                            style={{ height: "40px" }}
                        />
                    </APExpanded>
                    <APExpanded>
                        <APFormFieldDropdown
                            initialValue={activationData.nomineeRelation}
                            items={Constants.nomineeRelation.map(d => ({ value: d, label: d }))}
                            key={form.nomineeRelation}
                            label='Nominee Relationship'
                            onChanged={(v) => {
                                if (v) {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        nomineeRelation: v
                                    }));
                                }
                            }}
                            validator={(v) => {
                                if (!v) {
                                    return 'Nominee Relationship Required';
                                };
                                return null;
                            }}
                            style={{ height: "40px" }}
                        />
                    </APExpanded>
                    {nomineeCount > 1 && (
                        <APExpanded>
                            <APFormFieldText
                                keyboardType='number'
                                label="Nominee Share"
                                initialValue={form.nomineeShare.toString()}
                                onChanged={(v) => {
                                    if (v) {
                                        setForm((prevForm) => ({
                                            ...prevForm,
                                            nomineeShare: parseInt(v)
                                        }));
                                    }
                                }}
                                validator={(v) => {
                                    if (!v) {
                                        return 'Nominee share required';
                                    }
                                    if (form.nomineeShare + form.nomineeShare2 + form.nomineeShare3 != 100) {
                                        return "Total share should not exceed 100"
                                    };
                                    return null;
                                }}
                                style={{ height: "40px" }}
                            />
                        </APExpanded>
                    )}

                    {nomineeCount >= 2 && (
                        <>
                            <APRow mainAxisAlignment="spaceBetween">
                                <APText style={APTypography.h4}>Nominee 2</APText>
                                <APInkWell onClick={() => {
                                    setNomineeCount(nomineeCount - 1);
                                    setForm({
                                        ...form,
                                        nominee2: '',
                                        nomineeRelation2: '',
                                        nomineeShare2: 0,
                                    })
                                }}>
                                    <TrashIcon size={20} color={APPalette["negative-200"]} />
                                </APInkWell>
                            </APRow>
                            <APExpanded>
                                <APFormFieldText
                                    keyboardType='text'
                                    label="Nominee's Name"
                                    initialValue={form.nominee2}
                                    onChanged={(v) => {
                                        if (v) { }
                                    }}
                                    validator={(v) => {
                                        if (!v.length) {
                                            return 'Nominee name required';
                                        }
                                        return null;
                                    }}
                                    style={{ height: "40px" }}
                                />
                            </APExpanded>
                            <APExpanded>
                                <APFormFieldDropdown
                                    initialValue={activationData.nominee2Relation}
                                    items={Constants.nomineeRelation.map(d => ({ value: d, label: d }))}
                                    label='Nominee Relationship'
                                    onChanged={(v) => {

                                    }}
                                    validator={(v) => {
                                        if (!v) {
                                            return 'Nominee Relationship Required';
                                        };
                                        return null;
                                    }}
                                    style={{ height: "40px" }}
                                />
                            </APExpanded>
                            <APExpanded>
                                <APFormFieldText
                                    keyboardType='number'
                                    initialValue={form.nomineeShare2.toString()}
                                    label="Nominee Share"
                                    onChanged={(v) => {
                                        if (v) {
                                            setForm((prevForm) => ({
                                                ...prevForm,
                                                nomineeShare2: parseInt(v)
                                            }));
                                        }
                                    }}
                                    validator={(v) => {
                                        if (nomineeCount < 2) return null;

                                        if (!v) {
                                            return 'Nominee share required';
                                        }

                                        if (form.nomineeShare + form.nomineeShare2 + form.nomineeShare3 != 100) {
                                            return "Total share should not exceed 100"
                                        };

                                        return null;
                                    }}
                                    style={{ height: "40px" }}
                                />
                            </APExpanded>
                        </>
                    )}
                    {nomineeCount == 3 && (
                        <>
                            <APRow mainAxisAlignment="spaceBetween">
                                <APText style={APTypography.h4}>Nominee 3</APText>
                                <APInkWell onClick={() => {
                                    setNomineeCount(nomineeCount - 1);
                                    setForm({
                                        ...form,
                                        nominee3: '',
                                        nomineeRelation3: '',
                                        nomineeShare3: 0,
                                    })
                                }}>
                                    <TrashIcon size={20} color={APPalette["negative-200"]} />
                                </APInkWell>
                            </APRow>
                            <APExpanded>
                                <APFormFieldText
                                    keyboardType='text'
                                    initialValue={form.nominee3}
                                    label="Nominee's Name"
                                    onChanged={(v) => {
                                        if (v) {
                                            if (!v) return;
                                            setForm((prevForm) => ({
                                                ...prevForm,
                                                nominee3: v
                                            }));
                                        }
                                    }}
                                    validator={(v) => {
                                        if (v.length != 10) {
                                            return 'Nominee name required';
                                        }
                                        return null;
                                    }}
                                    style={{ height: "40px" }}
                                />
                            </APExpanded>
                            <APExpanded>
                                <APFormFieldDropdown
                                    initialValue={activationData.nominee3Relation}
                                    items={Constants.nomineeRelation.map(d => ({ value: d, label: d }))}
                                    key={form.nomineeRelation3}
                                    label='Nominee Relationship'
                                    onChanged={(v) => {
                                        if (!v) return;
                                        setForm((prevForm) => ({
                                            ...prevForm,
                                            nomineeRelation3: v
                                        }));
                                    }}
                                    validator={(v) => {
                                        if (!v) {
                                            return 'Nominee Relationship Required';
                                        };
                                        return null;
                                    }}
                                    style={{ height: "40px" }}
                                />
                            </APExpanded>
                            <APExpanded>
                                <APFormFieldText
                                    keyboardType='text'
                                    initialValue={form.nomineeShare3.toString()}
                                    label="Nominee Share"
                                    onChanged={(v) => {
                                        if (!v) return;
                                        setForm((prevForm) => ({
                                            ...prevForm,
                                            nomineeShare3: parseFloat(v)
                                        }));
                                    }}
                                    validator={(v) => {
                                        if (nomineeCount < 3) return null;
                                        if (v.length != 10) {
                                            return 'Nominee share required';
                                        }
                                        if (form.nomineeShare + form.nomineeShare2 + form.nomineeShare3 != 100) {
                                            return "Total share should not exceed 100"
                                        };
                                    }}
                                    style={{ height: "40px" }}
                                />
                            </APExpanded>
                        </>
                    )}
                    {nomineeCount < 3 && (
                        <APSizedBox width="180px">
                            <APButton
                                style={{ borderRadius: "8px" }}
                                leading={<PlusIcon color={APPalette["brand-300"]} />}
                                type="secondary"
                                onClick={() => {
                                    setForm((prevValue) => ({ ...prevValue, nomineeShare: 0, nomineeShare2: 0, nomineeShare3: 0 }))
                                    setNomineeCount(nomineeCount + 1);
                                }}
                            >Add another nominee</APButton>
                        </APSizedBox>
                    )
                    }
                </APColumn >
            ),
            onSubmit: async () => {
                const dataToSubmit: NomineeDetailsRequestClass = {
                    token: token!,
                    nominee1: { name: form.nominee, relation: form.nomineeRelation, share: form.nomineeShare }
                };

                if (nomineeCount >= 2) {
                    dataToSubmit.nominee2 = { name: form.nominee2, relation: form.nomineeRelation2, share: form.nomineeShare2 };
                };

                if (nomineeCount == 3) {
                    dataToSubmit.nominee2 = { name: form.nominee3, relation: form.nomineeRelation3, share: form.nomineeShare3 };
                };

                const updatedActivationData = await getIt(MutualFundApiServices).saveNomineeDetails(dataToSubmit);

                updateActivationData(updatedActivationData);
            }
        }
    );
}

export class NomineeDetails {
    name!: string
    relation!: string
    share?: number
    isMinor?: string
    DOB?: string
    guardian?: string
}

export class NomineeDetailsRequestClass {
    token!: string
    nominee1!: NomineeDetails
    nominee2?: NomineeDetails
    nominee3?: NomineeDetails
}