import { Divider } from '@mui/material';
import { APColumn, APDialogCard, APForm, APFormFieldDropdown, APFormFieldText, APFormSubmit, Deferred, handleErrorMessage, useAPForm } from 'ap-components'
import { useState } from 'react'
import NomineeMasterTable from '../../Models/NomineeMasterTable';
import { NomineeModel } from '../../Models/NomineeDeclarationModel';
import { NRVNomineeValidationModel } from '.';

export default function NomineeDialog({ deferred, details }: { deferred: Deferred<NRVNomineeValidationModel>, details: NomineeModel }) {
    const [form] = useState<NRVNomineeValidationModel>({
        name: details?.name ?? "",
        relationship: details?.relationship ?? "",
        relationshipCode: details?.relationshipCode ?? ""
    });

    var control = useAPForm();

    return (
        <APDialogCard title='Nominee Details' style={{ minWidth: "300px", maxWidth: "478px" }}>
            <APForm control={control}>
                <APColumn crossAxisAlignment="stretch">
                    <APFormFieldText
                        label='Nominee Name'
                        initialValue={form.name}
                        disabled
                    />
                    <APFormFieldDropdown<string>
                        label="Relationship"
                        initialValue={form.relationshipCode}
                        items={NomineeMasterTable.RELATIONSHIP_OPTIONS}
                        onChanged={(value) => {
                            if (value) {
                                const found = NomineeMasterTable.RELATIONSHIP_OPTIONS.find(x => x.value === value);
                                if (found) {
                                    form.relationship = found.label;
                                    form.relationshipCode = found.value;
                                }
                            }
                        }}
                        validator={(value) => {
                            if (!value) {
                                return "Select nominee relationship";
                            }

                            const found = NomineeMasterTable.RELATIONSHIP_OPTIONS.find(x => x.value === value);
                            if (!found) {
                                return "Invalid nominee relationship";
                            }

                            return null;
                        }}
                    />
                    <Divider />
                    <APFormSubmit
                        onClick={async () => {
                            if (await control.validate()) {
                                try {
                                    deferred.resolve(form)
                                } catch (error) {
                                    handleErrorMessage(error)
                                }
                            }
                        }}>
                        Next
                    </APFormSubmit>
                </APColumn>
            </APForm>
        </APDialogCard>
    )
}
