import { useLocation } from "@gatsbyjs/reach-router";
import { useState } from "react";
import { Constants } from "./ClientActivation";
import { APTypography } from "ap-components/src/utils/APTypography";
import Activation2Model, { ContactModel } from "../../Models/Activation2Model";
import MutualFundApiServices from "../../Services/MutualFundServices";
import { JourneyStepApp, APColumn, APPalette, APText, APExpanded, APFormFieldText, APFormFieldDropdown, REGEX, getIt } from "ap-components";

type ContactDetailsType = {
    primaryHolderMobile: string;
    primaryHolderMobileOwner: string;
    primaryHolderEmail: string;
    primaryHolderEmailOwner: string;
    secondaryHolderMobile: string;
    secondaryHolderMobileOwner: string;
    secondaryHolderEmail: string;
    secondaryHolderEmailOwner: string;
}

export default function ContactDetailsStepperFunction({ activationData, updateActivationData }: { activationData: Partial<Activation2Model>, updateActivationData: (activationData: Partial<Activation2Model>) => void }): JourneyStepApp {

    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const [form, setForm] = useState<ContactDetailsType>({
        primaryHolderMobile: !!activationData?.contact ? activationData.contact?.mobile : '',
        primaryHolderMobileOwner: !!activationData?.contact ? activationData.contact?.mobileOwner : '',
        primaryHolderEmail: !!activationData?.contact ? activationData.contact?.email : '',
        primaryHolderEmailOwner: !!activationData?.contact ? activationData.contact?.emailOwner : '',
        secondaryHolderMobile: activationData?.secondHolderContact ? activationData?.secondHolderContact?.mobile : '',
        secondaryHolderMobileOwner: activationData?.secondHolderContact ? activationData?.secondHolderContact?.mobileOwner : '',
        secondaryHolderEmail: activationData?.secondHolderContact ? activationData?.secondHolderContact?.email : '',
        secondaryHolderEmailOwner: activationData?.secondHolderContact ? activationData?.secondHolderContact?.emailOwner : '',
    });

    return ({
        title: "Contact Details",
        code: 'Contact Details',
        description: "",
        form: ((
            <APColumn key={activationData.activationWorkflowProgress} style={{ color: APPalette['grey-500'] }} crossAxisAlignment='stretch' gap='8px'>
                {activationData.holdingType == 'AS' && (
                    <APText style={APTypography.h4}>Holder 1: Fill details for {activationData?.name}</APText>
                )}
                <APExpanded>
                    <APFormFieldText
                        initialValue={form.primaryHolderMobile}
                        keyboardType='text'
                        label='Phone'
                        onChanged={(v) => {
                            if (v) {
                                setForm((prevForm) => ({
                                    ...prevForm,
                                    primaryHolderMobile: v
                                }));
                            }
                        }}
                        validator={(v) => {
                            if (v.length != 10) {
                                return 'Phone number should have 10 digits';
                            }
                            return null;
                        }}
                        style={{ height: "40px" }}
                    />
                </APExpanded>
                <APExpanded>
                    <APFormFieldDropdown
                        initialValue={form.primaryHolderMobileOwner}
                        items={Constants.ownershipOptions}
                        label='Phone belongs to'
                        onChanged={(v) => {
                            if (v) {
                                setForm((prevForm) => ({
                                    ...prevForm,
                                    primaryHolderMobileOwner: v
                                }));
                            }
                        }}
                        validator={(v) => {
                            if (!v) {
                                return 'Mobile ownership Required';
                            }
                            return null;
                        }}
                        style={{ height: "40px" }}
                    />
                </APExpanded>
                <APExpanded>
                    <APFormFieldText
                        initialValue={form.primaryHolderEmail}
                        keyboardType='text'
                        label='Email'
                        onChanged={(v) => {
                            if (v) {
                                setForm((prevForm) => ({
                                    ...prevForm,
                                    primaryHolderEmail: v
                                }));
                            }
                        }}
                        validator={(v) => {
                            if (!v) {
                                return 'Email is required'
                            }
                            if (!REGEX.EMAIL.test(v)) {
                                return 'Enter a valid email';
                            }
                            return null;
                        }}
                        style={{ height: "40px" }}
                    />
                </APExpanded>
                <APExpanded>
                    <APFormFieldDropdown
                        items={Constants.ownershipOptions}
                        initialValue={form.primaryHolderEmailOwner}
                        label='Email belongs to'
                        onChanged={(v) => {
                            if (v) {
                                setForm((prevForm) => ({
                                    ...prevForm,
                                    primaryHolderEmailOwner: v
                                }));
                            }
                        }}
                        validator={(v) => {
                            if (!v) {
                                return 'Email Ownership Required';
                            }
                            return null;
                        }}
                        style={{ height: "40px" }}
                    />
                </APExpanded>
                {activationData.holdingType == 'AS' && (<>
                    <APText style={APTypography.h4}>Holder 2: Fill details for {activationData?.secondHolderName}</APText>
                    <APExpanded>
                        <APFormFieldText
                            initialValue={form.secondaryHolderMobile}
                            keyboardType='text'
                            label='Phone'
                            onChanged={(v) => {
                                if (v) {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        secondaryHolderMobile: v
                                    }));
                                }
                            }}
                            validator={(v) => {
                                if (activationData.holdingType == 'SI') return null;
                                if (v.length != 10) {
                                    return 'Phone number should have 10 digits';
                                }
                                return null;
                            }}
                            style={{ height: "40px" }}
                        />
                    </APExpanded>
                    <APExpanded>
                        <APFormFieldDropdown
                            items={Constants.ownershipOptions}
                            label='Phone belongs to'
                            initialValue={form.secondaryHolderMobileOwner}
                            onChanged={(v) => {
                                if (v) {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        secondaryHolderMobileOwner: v
                                    }));
                                }
                            }}
                            validator={(v) => {
                                if (activationData.holdingType == 'SI') return null;
                                if (!v) {
                                    return 'Mobile ownership Required';
                                }
                                return null;
                            }}
                            style={{ height: "40px" }}
                        />
                    </APExpanded>
                    <APExpanded>
                        <APFormFieldText
                            initialValue={form.secondaryHolderEmail}
                            keyboardType='text'
                            label='Email'
                            onChanged={(v) => {
                                if (v) {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        secondaryHolderEmail: v
                                    }));
                                }
                            }}
                            validator={(v) => {
                                if (activationData.holdingType == 'SI') return null;

                                if (!v) {
                                    return 'Email is required'
                                }
                                if (!REGEX.EMAIL.test(v)) {
                                    return 'Enter a valid email';
                                }
                                return null;
                            }}
                            style={{ height: "40px" }}
                        />
                    </APExpanded>
                    <APExpanded>
                        <APFormFieldDropdown
                            items={Constants.ownershipOptions}
                            label='Email belongs to'
                            initialValue={form.secondaryHolderEmailOwner}
                            onChanged={(v) => {
                                if (v) {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        secondaryHolderEmailOwner: v
                                    }));
                                }
                            }}
                            validator={(v) => {
                                if (activationData.holdingType == 'SI') return null;

                                if (!v) {
                                    return 'Email Ownership Required';
                                }
                                return null;
                            }}
                            style={{ height: "40px" }}
                        />
                    </APExpanded>
                </>)}
            </APColumn>
        )),
        onSubmit: async () => {
            const dataToSubmit: { token: string, firstHolderContactDetails: ContactModel, secondHolderContactDetails?: ContactModel } = {
                token: token!,
                firstHolderContactDetails: {
                    mobile: form.primaryHolderMobile,
                    email: form.primaryHolderEmail,
                    mobileOwner: form.primaryHolderMobileOwner,
                    emailOwner: form.primaryHolderEmailOwner,
                }
            };

            if (activationData.holdingType == 'AS') {
                dataToSubmit.secondHolderContactDetails = {
                    mobile: form.secondaryHolderMobile,
                    email: form.secondaryHolderEmail,
                    mobileOwner: form.secondaryHolderMobileOwner,
                    emailOwner: form.secondaryHolderEmailOwner,
                }
            };


            const updatedActivationData = await getIt(MutualFundApiServices).saveContactDetails(dataToSubmit);

            updateActivationData(updatedActivationData);
        }
    })
}