import NotFoundPage from "../NotFoundPage";
import { Router } from '@gatsbyjs/reach-router';
import RegulatoryValidationRoutes from "./RegulatoryValidation/RegulatoryValidationRoutes";
import WebStoryRoutes from "./WebStory";
import HealthInsurance from "./HealthInsurance";
import Calculator from "./CalculatorTab/Calculator";
import ProfileFrameDownloader from "./ProfileFrameDownloader";
import { KYCContactValidation } from "./KYCContactValidation/KycContactValidationForm";
import InsuranceTerm from "./InsuranceTerm";
import LAMFVoltMoney from "./LAMFVoltMoney";
import DigilockerKYCPage from "./DigilockerKYC/DigilockerKYC";
import ClientActivationPage from "./ClientActivation/ClientActivation";
import NomineeRegulatoryValidationRoutes from "./NomineeRegulatoryValidation/NomineeRegulatoryValidationRoutes";

export default function App() {
    return (
        <Router style={{ display: "flex", height: "100%" }}>
            <NotFoundPage path="/" default />
            <RegulatoryValidationRoutes path="/regulatory_validation/*" />
            <NomineeRegulatoryValidationRoutes path="/nominee_regulatory_validation/*" />
            <DigilockerKYCPage path="/digilocker_kyc/*" />
            <WebStoryRoutes path="/web_story/*" />
            <HealthInsurance path="/ins_health/*" />
            <InsuranceTerm path="ins_term" />
            <Calculator path="calculators/:calculator" />
            <ProfileFrameDownloader path="profile_frame_downloader" />
            <KYCContactValidation path="kyc_contact_validation" />
            <LAMFVoltMoney path="lamf" />
            <ClientActivationPage path="activation" />
        </Router>
    );
}