export default class NomineeMasterTable {
    static RELATIONSHIP_OPTIONS = [
        { value: "01", label: "Aunt" },
        { value: "02", label: "Brother In Law" },
        { value: "03", label: "Brother" },
        { value: "04", label: "Daughter" },
        { value: "05", label: "Daughter In Law" },
        { value: "06", label: "Father" },
        { value: "07", label: "Father In Law" },
        { value: "08", label: "Grand Daughter" },
        { value: "09", label: "Grand Father" },
        { value: "10", label: "Grand Mother" },
        { value: "11", label: "Grand Son" },
        { value: "12", label: "Mother In Law" },
        { value: "13", label: "Mother" },
        { value: "14", label: "Nephew" },
        { value: "15", label: "Niece" },
        { value: "16", label: "Sister" },
        { value: "17", label: "Sister In Law" },
        { value: "18", label: "Son" },
        { value: "19", label: "Son In Law" },
        { value: "20", label: "Spouse" },
        { value: "21", label: "Uncle" },
        { value: "22", label: "Others" },
    ]

    static GUARDIAN_RELATIONSHIP_OPTIONS = [
        { value: "06", label: "Father" },
        { value: "13", label: "Mother" },
        { value: "23", label: "Court Appointed Legal Guardian" },
    ]
}