import { Router, RouteComponentProps } from '@gatsbyjs/reach-router';
import NomineeRegulatoryValidation from '.';
import SuccessPage from './SuccessPage';


export default function NomineeRegulatoryValidationRoutes(props: RouteComponentProps) {
  return (
    <Router style={{ display: "flex", height: "100%" }}>
      <NomineeRegulatoryValidation path="/" default />
      <SuccessPage path="/successPage" />
    </Router>
  )
}
