import Axios, { AxiosInstance } from "axios";
import { singleton } from "tsyringe";
import { SaveAddressDetailsRequestClass } from "../App/ClientActivation/AddressDetails";
import { VerifyBankRequestClass } from "../App/ClientActivation/BankVerificationPage";
import { NomineeDetailsRequestClass } from "../App/ClientActivation/NomineeDetails";
import { PersonalRequestDetails, MinorDetailsType, DematDetailsType } from "../App/ClientActivation/PersonalDetails";
import { PhotoRequestForHolderClass } from "../App/ClientActivation/PhotoPage";
import { SignatureRequestForHolderClass } from "../App/ClientActivation/SignaturePage";
import Activation2Model, { HoldingType, TaxStatus, ContactModel, UserForActivationWorkflow } from "../Models/Activation2Model";
import { ValidatePANDetailsRequestType } from "./ClientApiServices";
import { plainToClass } from "class-transformer";
import NomineeDeclarationModel, { NomineeApiReq } from "../Models/NomineeDeclarationModel";

export const SERVER_URL = import.meta.env.REACT_APP_MUTUAL_FUND_SERVER_URL ?? "";

@singleton()
export default class MutualFundApiServices {
	instance: AxiosInstance = Axios.create({
		baseURL: SERVER_URL
	});

	constructor() {
		this.instance.interceptors.request.use((config) => {
			return config;
		}, (error => {
			return Promise.reject(error);
		}));

		this.instance.interceptors.response.use((v) => v, (error) => {
			if (error?.response?.data?.userMsg) {
				error.message = error.response.data.userMsg;
			}
			if (error?.response?.data?.metaData) {
				error.metaData = error.response.data.metaData;
			}
			return Promise.reject(error);
		})
	}

	async getLamfSdkCredentials({ mwebToken, otp }: { mwebToken: string, otp: string }): Promise<{ message: string, voltCustomerCode: string, authToken: string, ssoToken: string }> {
		let res = await this.instance.request({
			method: "POST",
			url: "/mweb/lamf/getVoltSdkCredentials",
			data: {
				mwebToken,
				otp,
			}
		});
		return res.data;
	}

	async generateOTP(token: string): Promise<{ message: string, maskedEmail: string, maskedPhoneNumber: string }> {
		let res = await this.instance.request({
			method: "GET",
			url: `/mweb/lamf/generateOtp?token=${token}`,
		});
		return res.data;
	}

	async kyc(req: { token: string }): Promise<KYCDigilockerSchema> {
		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/digilocker_kyc`,
			data: req
		});
		return res.data as KYCDigilockerSchema;
	}
	async getDigilockerUrl(req: {
		token: string,
		redirectUrl: string,
	}): Promise<{ url: string }> {

		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/digilocker_kyc/getDigilockerUrl`,
			data: req
		});
		return res.data;

	}
	async fetchDigilockerStatus(req: {
		token: string,
	}): Promise<void> {

		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/digilocker_kyc/fetchDigilockerStatus`,
			data: req
		});
		return res.data;

	}


	async saveIdentityProof(req: {
		token: string,
		name: string,
		dob: string,
		fatherName: string,
		panNumber: string
	}): Promise<void> {

		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/digilocker_kyc/saveIdentityProof`,
			data: req
		});
		return res.data;

	}
	async saveAddressProof(req: {
		token: string,
		address: string,
		city: string,
		state: string,
		district: string,
		pincode: string,
		aadhaarLast4Digits: string,
	}): Promise<void> {

		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/digilocker_kyc/saveAddressProof`,
			data: req
		});
		return res.data;

	}
	async saveKYCDetails(req: {
		token: string,
		gender: string,
		maritalStatus: string,
		motherName: string,
		occupationDescription: string,
		occupationCode: string,
		pep: string,
		rpep: string,
	}): Promise<void> {

		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/digilocker_kyc/saveKYCDetails`,
			data: req
		});

		return res.data;

	}
	async savePhotoAndSignature(req: {
		token: string,
		photoBase64: string,
		signatureBase64: string,
	}): Promise<void> {

		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/digilocker_kyc/savePhotoAndSignature`,
			data: req
		});

		return res.data;

	}
	async uploadPANImage(req: {
		token: string,
		panImageBase64: string,
	}): Promise<{ fatherName: string, name: string, dob: string, panNumber: string }> {

		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/digilocker_kyc/uploadPANImage`,
			data: req
		});

		return res.data;

	}

	async generateEsignUrl(req: {
		token: string,
		redirectUrl: string,
	}): Promise<{ url: string }> {

		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/digilocker_kyc/generateEsignUrl`,
			data: req
		});

		return res.data;

	}

	async executeEsign(req: {
		token: string
	}): Promise<void> {

		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/digilocker_kyc/executeEsign`,
			data: req
		});

		return res.data;

	}


	async submitApplication(req: {
		token: string
	}): Promise<void> {
		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/digilocker_kyc/submitApplication`,
			data: req
		});

		return res.data;
	}

	async getActivationDetails(token: string): Promise<Activation2Model> {
		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/activation/getActivationDetails`,
			data: { token }
		});
		return res.data;
	};

	async validatePanDetails({ token, firstHolderPanDetails, secondHolderPanDetails }: ValidatePANDetailsRequestType): Promise<{ firstHolderName: string, secondHolderName: string }> {
		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/activation/validatePanDetails`,
			data: { token, firstHolderPanDetails, secondHolderPanDetails }
		});
		return res.data;
	};

	async validateKycStatusAndSaveBasicDetails({ token, taxStatus, holdingType, firstHolderPanDetails, secondHolderPanDetails }: BasicDetailsReqClass): Promise<Activation2Model> {
		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/activation/validateKycStatusAndSaveBasicDetails`,
			data: { token, holdingType, taxStatus, firstHolderPanDetails, secondHolderPanDetails }
		});

		return res.data;
	};

	async saveContactDetails({ token, firstHolderContactDetails, secondHolderContactDetails }: { token: string, firstHolderContactDetails: ContactModel, secondHolderContactDetails?: ContactModel }): Promise<Activation2Model> {
		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/activation/saveContactDetails`,
			data: { token, firstHolderContactDetails, secondHolderContactDetails }
		});
		return res.data;
	};

	async getDigiLockerPanStatus({ token, }: { token: string, }): Promise<{ primaryHolder: boolean, secondaryHolder: boolean }> {
		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/activation/getDigiLockerPanStatus`,
			data: { token }
		});
		return res.data;
	};

	async savePersonalDetails({ token, firstHolderDetails, secondHolderDetails, minorDetails, dematDetails }: { token: string, firstHolderDetails: PersonalRequestDetails, secondHolderDetails?: PersonalRequestDetails, minorDetails?: MinorDetailsType, dematDetails?: DematDetailsType }): Promise<Activation2Model> {
		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/activation/savePersonalDetails`,
			data: { token, firstHolderDetails, secondHolderDetails, minorDetails, dematDetails }
		});
		return res.data;
	};

	async uploadPANImage2(req: { token: string, panImageBase64: string, holder: UserForActivationWorkflow }): Promise<Partial<Activation2Model>> {

		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/activation/uploadPANImage`,
			data: req
		});
		return res.data;
	}


	async saveAddressDetails(data: SaveAddressDetailsRequestClass): Promise<Activation2Model> {
		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/activation/saveAddressDetails`,
			data
		});
		return res.data;
	};

	async saveNomineeDetails(data: NomineeDetailsRequestClass): Promise<Activation2Model> {
		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/activation/saveNomineeDetails`,
			data
		});
		return res.data;
	};

	async saveSignatureImageForHolder(data: SignatureRequestForHolderClass): Promise<Activation2Model> {
		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/activation/saveSignatureImageForHolder`,
			data
		});
		console.log({ data })
		return res.data;
	};

	async savePhotoImageForHolder(data: PhotoRequestForHolderClass): Promise<Activation2Model> {
		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/activation/savePhotoImageForHolder`,
			data
		});
		return res.data;
	};

	async verifyBank(data: VerifyBankRequestClass): Promise<Activation2Model> {
		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/activation/verifyBank`,
			data
		});
		return res.data;
	};

	async getDigilockerUrl2(req: { token: string, holder: UserForActivationWorkflow, redirectUrl: string }): Promise<{ url: string }> {

		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/activation/getDigilockerUrl`,
			data: req
		});
		return res.data;
	}

	async fetchDigilockerStatus2(req: { token: string, holder: UserForActivationWorkflow, refetch?: boolean }): Promise<Activation2Model> {

		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/activation/fetchDigilockerStatus`,
			data: req
		});
		return res.data;
	}

	async triggerActivation(req: { token: string }): Promise<String> {
		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/activation/triggerActivation`,
			data: req
		});
		return res.data;
	}

	async generateEsignUrl2(req: { token: string, redirectUrl: string, holder: UserForActivationWorkflow }): Promise<{ url: string }> {

		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/activation/generateEsignUrl`,
			data: req
		});

		return res.data;

	}

	async executeEsign2(req: { token: string, holder: UserForActivationWorkflow }): Promise<Activation2Model> {

		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/activation/executeEsign`,
			data: req
		});

		return res.data;

	}


	async submitApplication2(req: { token: string, holder: UserForActivationWorkflow }): Promise<Activation2Model> {
		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/activation/submitApplication`,
			data: req
		});

		return res.data;
	}

	async refetchKycDetails(req: { token: string, holder: UserForActivationWorkflow }): Promise<Activation2Model> {
		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/activation/refetchKycDetails`,
			data: req
		});

		return res.data;
	}

	async getFile(req: { token: string, holder: UserForActivationWorkflow, file: 'signature' | 'photo' }): Promise<string> {
		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/activation/getFile`,
			data: req
		});

		return res.data;
	}

	async isPartnerWorkflow(token: string): Promise<boolean> {
		let res = await this.instance.request({
			method: "POST",
			url: `/mweb/activation/isPartnerWorkflow`,
			data: { token }
		});

		return res.data;
	}

	async getDetails(token: string) {
		var response = await this.instance.request({
			method: 'GET',
			url: "/user/activation/selfDeclaration/details",
			headers: {
				Authorization: "Bearer " + token
			}
		});

		return plainToClass(NomineeDeclarationModel, response.data)
	}

	async updateSelfValidation(data: NomineeApiReq, token: string) {
		await this.instance.request({
			method: 'POST',
			url: "/user/activation/selfDeclaration/updateSelf",
			headers: {
				Authorization: "Bearer " + token
			},
			data
		});
	}

}


export class HolderPANDetails {
	panNumber!: string;
	dob!: string;
}

export class BasicDetailsReqClass {
	token!: string;
	holdingType!: HoldingType;
	taxStatus!: TaxStatus;
	firstHolderPanDetails!: HolderPANDetails;
	secondHolderPanDetails?: HolderPANDetails
}

export class KYCDigilockerSchema {

	userId!: string

	kycType!: "NEW" | "MODIFICATION"

	digilockerStatus!: "PENDING" | "INITIATED" | "APPROVED"

	name!: string

	email!: string

	mobile!: string

	signzyOnboardingId!: string

	signzyUsername!: string

	signzyPassword!: string

	signzyUserId!: string

	signzyAccessToken!: string

	dob?: string

	fatherName?: string

	panNumber?: string

	panDocumentType?: string

	panImageBase64?: string

	address?: string

	city?: string

	state?: string

	pincode?: string

	district?: string

	aadhaarLast4Digits?: string

	gender?: "M" | "F" | "T"

	maritalStatus?: "MARRIED" | "UNMARRIED"

	motherName?: string

	occupationCode?: string
	occupationDescription?: string

	pep?: "NO" | "YES"

	rpep?: "NO" | "YES"

	photoBase64?: string

	signatureBase64?: string


	applicationStatus?: string


}