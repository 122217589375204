import { Divider } from '@mui/material';
import { APColumn, APDialogCard, APForm, APFormFieldDropdown, APFormFieldText, APFormSubmit, Deferred, handleErrorMessage, useAPForm } from 'ap-components'
import { useState } from 'react'
import NomineeMasterTable from '../../Models/NomineeMasterTable';
import { GuardianModel } from '../../Models/NomineeDeclarationModel';
import { NRVGuardianValidationModel } from '.';

export default function GuardianDialog({ deferred, details }: { deferred: Deferred<NRVGuardianValidationModel>, details: GuardianModel }) {
    const [form] = useState<NRVGuardianValidationModel>({
        name: details?.name ?? "",
        relationshipCode: details?.relationshipCode ?? ""
    });

    var control = useAPForm();

    return (
        <APDialogCard title='Guardian Details' style={{ minWidth: "300px", maxWidth: "478px" }}>
            <APForm control={control}>
                <APColumn crossAxisAlignment="stretch">
                    <APFormFieldText
                        label='Guardian Name'
                        initialValue={form.name}
                        disabled
                    />
                    <APFormFieldDropdown<string>
                        label="Relationship"
                        initialValue={form.relationshipCode}
                        items={NomineeMasterTable.GUARDIAN_RELATIONSHIP_OPTIONS}
                        onChanged={(value) => {
                            if (value) {
                                form.relationshipCode = value;
                            }
                        }}
                        validator={(value) => {
                            if (!value) {
                                return "Select guardian relationship";
                            }

                            return null;
                        }}
                    />
                    <Divider />
                    <APFormSubmit
                        onClick={async () => {
                            if (await control.validate()) {
                                try {
                                    deferred.resolve(form)
                                } catch (error) {
                                    handleErrorMessage(error)
                                }
                            }
                        }}>
                        Next
                    </APFormSubmit>
                </APColumn>
            </APForm>
        </APDialogCard>
    )
}
