import { useLocation } from "@gatsbyjs/reach-router";
import { APTypography } from "ap-components/src/utils/APTypography";
import { useEffect, useState } from "react";
import Activation2Model, { UserForActivationWorkflow } from "../../Models/Activation2Model";
import { addParamsToCurrentUrl, launchUrl } from "../DigilockerKYC/common";
import MutualFundApiServices from "../../Services/MutualFundServices";
import { JourneyStepApp, JourneySectionApp, getIt, APColumn, APSizedBox, APImage, APTag, APText, APCenter } from "ap-components";
import { CircularProgress } from "@mui/material";

export default function EsignStepper({ activationData, updateActivationData }: { activationData: Partial<Activation2Model>, updateActivationData: (activationData: Partial<Activation2Model>) => void }): JourneySectionApp {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const [isFetchingUpdate, setisFetchingUpdate] = useState<boolean>(false);
    const currentUser: UserForActivationWorkflow = activationData.activationWorkflowProgress === 'E-Sign___SH' ? 'secondary' : activationData.taxStatus != '02' ? 'primary' : 'guardian';
    const statusesThatNeedsRefresh = ["INITIATED", "PENDING"];
    if (!activationData.activationWorkflowProgress?.includes('DigiLocker')) {
        let url = new URL(window.location.href);
        let isRedirected = searchParams.get("kyc_digilocker_stage") == 'redirected';
        let isDigilockerComeback = searchParams.get("kyc_step") == 'Digilocker';
        if (isRedirected || isDigilockerComeback) {
            ["kyc_digilocker_stage", "kyc_step"].forEach(param => url.searchParams.delete(param));
            launchUrl(url.href);
        }
    }

    async function launchEsignPage(holder: UserForActivationWorkflow) {
        setisFetchingUpdate(true);
        let { url } = await getIt(MutualFundApiServices).generateEsignUrl2({ token: token!, redirectUrl: addParamsToCurrentUrl({ kyc_esign_stage: "redirected", kycStep: "E-Sign", }), holder: holder });
        launchUrl(url);
        setisFetchingUpdate(false);
        throw new Error("Redirecting to Esign");
    }

    async function performEsign() {
        if ((activationData.activationWorkflowProgress === 'E-Sign___FH' || activationData.activationWorkflowProgress === 'E-Sign___SH')) {
            if (((currentUser == 'primary' && statusesThatNeedsRefresh.includes(activationData.esignStatus ?? '')) ||
                (currentUser === 'guardian' && statusesThatNeedsRefresh.includes(activationData.guardianEsignStatus ?? ''))) ||
                (currentUser === 'secondary' && statusesThatNeedsRefresh.includes(activationData.secondHolderEsignStatus ?? ''))) {

                setisFetchingUpdate(true);

                try {
                    await getIt(MutualFundApiServices).executeEsign2({ token: token!, holder: currentUser });
                    await getIt(MutualFundApiServices).refetchKycDetails({ token: token!, holder: currentUser });
                    updateActivationData(await getIt(MutualFundApiServices).submitApplication2({ token: token!, holder: currentUser }));
                    setisFetchingUpdate(false);
                    return
                } catch (error) {
                    await launchEsignPage(currentUser);
                }
            } else {
                await launchEsignPage(currentUser);
            }
        }
    }

    useEffect(() => {
        if (
            (currentUser === 'primary' && statusesThatNeedsRefresh.includes(activationData.esignStatus ?? '')) ||
            (currentUser === 'guardian' && statusesThatNeedsRefresh.includes(activationData.guardianEsignStatus ?? '')) ||
            (currentUser === 'secondary' && statusesThatNeedsRefresh.includes(activationData.secondHolderEsignStatus ?? ''))
        ) {
            setisFetchingUpdate(true);
            performEsign();
        }
    }, [])

    const steps: JourneyStepApp[] = [];
    if (!activationData.firstHolderHasCkyc) {
        steps.push({
            title: activationData.holdingType == 'SI' ? "e-Sign" : "e-Sign for Holder 1",
            description: activationData.taxStatus !== '02' ? activationData.name ?? '' : activationData.guardianName ?? '',
            code: "FH",
            form: (isFetchingUpdate ? <APCenter><CircularProgress /></APCenter> : (
                <APColumn crossAxisAlignment="start">
                    <APSizedBox height='16px' />
                    <APColumn style={{ padding: '16px' }} crossAxisAlignment='center' gap='32px'>
                        <APImage width={118} height={120} src='img/DigiLocker_info.svg' />
                        {((currentUser === 'primary' && (activationData.esignStatus ?? '') === "COMPLETE") || (currentUser === 'guardian' && (activationData.guardianEsignStatus ?? '') === "COMPLETE")) && (<APTag variant="positive">e-Sign completed successfully. Click on Next to continue</APTag>)}
                        <APColumn crossAxisAlignment="start">
                            <APText style={APTypography.h4}>E-sign KYC ensures your identity is securely verified, complies with legal requirements, and makes the process fast and convenient.</APText>
                        </APColumn>
                    </APColumn>
                </APColumn>
            )),
            onSubmit: isFetchingUpdate ? () => { throw new Error("Please Wait") } : async () => {
                await performEsign();
            }
        });
    };

    if (activationData.holdingType == 'AS' && !activationData.secondHolderHasCkyc) {
        steps.push({
            title: "e-Sign for Holder 2",
            description: activationData.secondHolderName ?? '',
            code: "SH",
            form: (
                <APColumn crossAxisAlignment="start">
                    <APSizedBox height='16px' />

                    <APColumn style={{ padding: '16px' }} crossAxisAlignment='center' gap='8px'>
                        <APImage width={118} height={120} src='img/DigiLocker_info.svg' />
                        <APSizedBox height="32px" />
                        <APImage width={114.738} height={28.069} src='img/Growth.svg' />
                        <APSizedBox height="32px" />
                        {(activationData.secondHolderEsignStatus ?? '') === "COMPLETE" && (<APTag variant="positive">e-Sign completed successfully. Click on Next to continue</APTag>)}
                        <APColumn crossAxisAlignment="start">
                            <APText style={APTypography.h4}>E-sign KYC ensures your identity is securely verified, complies with legal requirements, and makes the process fast and convenient.</APText>
                        </APColumn>
                    </APColumn>
                    <APSizedBox height='16px' />
                </APColumn>
            ),
            onSubmit: isFetchingUpdate ? () => { throw new Error("Please Wait") } : async () => {
                await performEsign();
            }
        });
    };

    return {
        code: "E-Sign",
        title: "E-Sign",
        description: activationData.name ?? '',
        steps
    };
};


export class PhotoRequestForHolderClass {
    token!: string;
    photoBase64!: string;
    holder!: 'primary' | 'secondary' | 'guardian'
}