import { useLocation } from "@gatsbyjs/reach-router";
import { APColumn, APSizedBox, APText, APButton, APCard, APFormFieldImage, APStack, APAlign, CloseIcon, getIt, JourneySectionApp, JourneyStepApp, APInkWell, APPadding } from "ap-components";
import { APTypography } from "ap-components/src/utils/APTypography";
import { useState, useRef, useEffect } from "react";
import Activation2Model from "../../Models/Activation2Model";
import SignaturePad from "react-signature-canvas";
import MutualFundApiServices from "../../Services/MutualFundServices";

export default function SignatureStepperFunction({ activationData, updateActivationData }: { activationData: Partial<Activation2Model>, updateActivationData: (activationData: Activation2Model) => void }): JourneySectionApp {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const [showSignatureComponent, setShowSignatureComponent] = useState(true);
    const [showSHSignatureComponent, setShowSHSignatureComponent] = useState(true);
    const sigCanvas = useRef<any>({});
    const [signatureImage, setSignatureImage] = useState<string>();
    const [secondHolderSignatureImage, setSecondHolderSignatureImage] = useState<string>();
    const clear = () => sigCanvas.current.clear();

    const { innerWidth: width, innerHeight: height } = window;
    const signatureComponentWidth = `${((width * 0.85) - 16).toFixed(0)}px`;
    const signatureComponentHeight = `${(height * 0.58).toFixed(0)}px`;

    async function getSignatureImage(holder: 'primary' | 'secondary' | 'guardian') {
        const signatureBase64 = await getIt(MutualFundApiServices).getFile({ token: token!, holder, file: "signature" });
        if (holder != 'secondary') {
            setSignatureImage(signatureBase64);
        } else {
            setSecondHolderSignatureImage(signatureBase64);
        }
    };
    useEffect(() => {
        if (activationData.imageSignature) {
            setShowSignatureComponent(false);
            getSignatureImage('primary');
        };
        if (activationData.secondHolderImageSignature) {
            setShowSignatureComponent(false);
            getSignatureImage('secondary');
        }
    }, []);

    const steps: JourneyStepApp[] = [
        {
            title: activationData.holdingType == 'SI' ? "Signature" : "Signature for Holder 1",
            description: activationData.taxStatus !== '02' ? activationData.name ?? '' : activationData.guardianName ?? '',
            code: "FH",
            form: (
                <APColumn crossAxisAlignment="start">
                    {showSignatureComponent ? (
                        <>
                            <APText style={APTypography.h4}>Please upload or sign here</APText>
                            <APStack>
                                <APColumn crossAxisAlignment='center'>
                                    <SignaturePad
                                        ref={sigCanvas}
                                        canvasProps={{ className: "signatureCanvas", width: signatureComponentWidth, height: signatureComponentHeight, style: { border: '1px dashed  #2B62C0', borderRadius: "8px" } }}
                                    />
                                </APColumn>
                                <APPadding padding="5px 7px 0px 0px">
                                    <APAlign align="topRight">
                                        <APInkWell onClick={clear}>
                                            <CloseIcon />
                                        </APInkWell>
                                    </APAlign>
                                </APPadding>
                            </APStack>
                        </>
                    ) : (
                        <APColumn crossAxisAlignment='stretch' gap='8px'>
                            <APCard>
                                <APColumn crossAxisAlignment="start">
                                    <APText style={APTypography.h4}>Please upload or sign here</APText>
                                    <APText style={APTypography["paragraph-small"]}>• Sign on a blank sheet of paper.</APText>
                                    <APText style={APTypography["paragraph-small"]}>• Please make sure the uploaded signature is clear to avoid future rejections in investment/redemptions.</APText>
                                </APColumn>
                            </APCard>
                            {activationData.secondHolderName && (
                                <APText style={APTypography.h4}>Signature for Holder 1</APText>
                            )}
                            <APFormFieldImage
                                key={signatureImage}
                                label="Image"
                                src={signatureImage}
                                onChange={(src) => {
                                    setSignatureImage(src)
                                }}
                                validator={(v) => !v || v.length === 0 ? "Please select an image" : null}
                            />
                        </APColumn>
                    )
                    }
                    {!signatureImage && <>
                        <APText onClick={() => setShowSignatureComponent(!showSignatureComponent)} style={APTypography['link-medium']}>
                            {showSignatureComponent ? 'I want to upload instead' : 'I want to sign instead'}
                        </APText>
                    </>}
                </APColumn >
            ),
            onSubmit: async () => {
                if (showSignatureComponent && sigCanvas.current.isEmpty() || !showSignatureComponent && !signatureImage) {
                    throw new Error("Signature required");
                }
                const dataToSubmit: SignatureRequestForHolderClass = {
                    token: token!,
                    signatureBase64: showSignatureComponent ? sigCanvas.current.getTrimmedCanvas().toDataURL("image/png") : signatureImage,
                    holder: 'primary'
                };

                const updatedActivationData = await getIt(MutualFundApiServices).saveSignatureImageForHolder(dataToSubmit);

                updateActivationData(updatedActivationData);
            }
        }
    ];


    if (activationData.holdingType == 'AS') {
        steps.push({
            title: "Signature for Holder 2",
            description: activationData.secondHolderName ?? '',
            code: "SH",
            form: (
                <APColumn crossAxisAlignment="start">
                    {showSHSignatureComponent ? (
                        <>
                            <APText style={APTypography.h4}>Please upload or sign here</APText>
                            <APSizedBox height='16px' />
                            <APStack>
                                <APColumn crossAxisAlignment='center'>
                                    <SignaturePad ref={sigCanvas} canvasProps={{ className: "signatureCanvas", width: signatureComponentWidth, height: signatureComponentHeight, style: { border: '1px dashed  #2B62C0', borderRadius: "8px" } }} />
                                </APColumn>
                                <APAlign align="topRight">
                                    <APButton
                                        size="small"
                                        type="tertiary"
                                        onClick={clear}>
                                        <CloseIcon />
                                    </APButton>
                                </APAlign>
                            </APStack>
                        </>
                    ) : (
                        <APColumn crossAxisAlignment='stretch' gap='8px'>
                            <APCard>
                                <APColumn crossAxisAlignment="start">
                                    <APText style={APTypography.h4}>Please upload or sign here</APText>
                                    <APText style={APTypography["paragraph-small"]}>• Sign on a blank sheet of paper.</APText>
                                    <APText style={APTypography["paragraph-small"]}>• Please make sure the uploaded signature is clear to avoid future rejections in investment/redemptions.</APText>
                                </APColumn>
                            </APCard>
                            {activationData.secondHolderName && (
                                <APText style={APTypography.h4}>Signature for Holder 2</APText>
                            )}
                            <APFormFieldImage
                                label="Image"
                                key={secondHolderSignatureImage}
                                src={secondHolderSignatureImage}
                                onChange={(src) => {
                                    if (!!src) {
                                        setSecondHolderSignatureImage(src)
                                    }
                                }}
                                validator={(v) => !v || v.length === 0 ? "Please select an image" : null}
                            />
                        </APColumn>
                    )}
                    {!secondHolderSignatureImage && <>
                        <APSizedBox height='16px' />
                        <APText onClick={() => setShowSignatureComponent(!showSHSignatureComponent)} style={APTypography['link-medium']}>
                            {showSHSignatureComponent ? 'I want to upload instead' : 'I want to sign instead'}
                        </APText>
                    </>}
                </APColumn>
            ),
            onSubmit: async () => {
                if (showSHSignatureComponent && sigCanvas.current.isEmpty() || !showSHSignatureComponent && !secondHolderSignatureImage) {
                    throw new Error("Signature required");
                }
                const dataToSubmit: SignatureRequestForHolderClass = {
                    token: token!,
                    signatureBase64: showSHSignatureComponent ? sigCanvas.current.getTrimmedCanvas().toDataURL("image/png") : secondHolderSignatureImage,
                    holder: 'secondary'
                };

                const updatedActivationData = await getIt(MutualFundApiServices).saveSignatureImageForHolder(dataToSubmit);

                updateActivationData(updatedActivationData);
            }
        },)
    }

    return {
        title: "Signature",
        code: "Signature",
        description: activationData.name ?? '',
        steps: steps
    };
};


export class SignatureRequestForHolderClass {
    token!: string;
    signatureBase64!: string;
    holder!: 'primary' | 'secondary'
}