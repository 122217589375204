import { useLocation } from "@gatsbyjs/reach-router";
import { APTypography } from "ap-components/src/utils/APTypography";
import Activation2Model, { UserForActivationWorkflow } from "../../Models/Activation2Model";
import { addParamsToCurrentUrl, launchUrl } from "../DigilockerKYC/common";
import MutualFundApiServices from "../../Services/MutualFundServices";
import { useEffect, useState } from "react";
import { JourneyStepApp, JourneySectionApp, getIt, APColumn, APSizedBox, APImage, APText, APTag, APRow, APExpanded, APCenter, sleep } from "ap-components";
import { CircularProgress } from "@mui/material";

export default function DigiLockerStepperFunction({ activationData, updateActivationData }: { activationData: Partial<Activation2Model>, updateActivationData: (activationData: Partial<Activation2Model>) => void }): JourneySectionApp {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const [isFetchingUpdate, setisFetchingUpdate] = useState<boolean>(false);
    const [wrongAadhar, setWrongAadhar] = useState<boolean>(false);
    const { holdingType, taxStatus } = activationData;
    const currentUser: UserForActivationWorkflow = activationData.activationWorkflowProgress === 'DigiLocker___SH' ? 'secondary' : activationData.taxStatus != '02' ? 'primary' : 'guardian';
    const addParamsConfig = { kyc_digilocker_stage: "redirected", kyc_step: "Digilocker" };
    const statusesThatNeedsRefresh = ["INITIATED", "PENDING"];
    function updateUrl() {
        let url = new URL(window.location.href);
        let isRedirected = searchParams.get("kyc_digilocker_stage") == 'redirected';
        let isDigilockerComeback = searchParams.get("kyc_step") == 'Digilocker';
        if (isRedirected || isDigilockerComeback) {
            ["kyc_digilocker_stage", "kyc_step"].forEach(param => url.searchParams.delete(param));
            launchUrl(url.href);
        }
    }


    async function launchDigilockerPage() {
        let { url } = await getIt(MutualFundApiServices).getDigilockerUrl2({ token: token!, holder: currentUser, redirectUrl: addParamsToCurrentUrl(addParamsConfig) });
        launchUrl(url);
    }

    async function performDigiLockerProcess() {
        setisFetchingUpdate(true);

        if (!wrongAadhar) {
            try {
                const updatedActivationData = await getIt(MutualFundApiServices).fetchDigilockerStatus2({ token: token!, holder: currentUser });
                updateActivationData(updatedActivationData);
                updateUrl();
                setisFetchingUpdate(false);
                return;
            } catch (error: any) {
                const { metaData, message } = error;
                if (!!metaData && metaData.reason === "AADHAR Number do not match") {
                    setisFetchingUpdate(false);
                    setWrongAadhar(true);
                    throw new Error("Wrong AADHAR entered");
                }
            }
        }

        launchDigilockerPage();
        setisFetchingUpdate(false);
        throw new Error("Redirecting to Digilocker");
    }

    useEffect(() => {
        if (
            (currentUser === 'primary' && statusesThatNeedsRefresh.includes(activationData.firstHolderDigiLockerWorkflowStatus ?? '')) ||
            (currentUser === 'guardian' && statusesThatNeedsRefresh.includes(activationData.guardianDigiLockerWorkflowStatus ?? '')) ||
            (currentUser === 'secondary' && statusesThatNeedsRefresh.includes(activationData.secondHolderDigiLockerWorkflowStatus ?? ''))
        ) {
            performDigiLockerProcess();
        }
    }, []);

    const steps: JourneyStepApp[] = [];
    if ((currentUser === 'primary' && !activationData.firstHolderHasCkyc) || (currentUser === 'guardian' && !activationData.guardianHasCkyc)) {
        steps.push({
            title: `DigiLocker${activationData.holdingType == 'SI' ? '' : ' for Holder 1'}`,
            description: taxStatus !== '02' ? activationData.name ?? '' : activationData.guardianName ?? '',
            code: "FH",
            form: (
                isFetchingUpdate ?
                    <APExpanded>
                        <APCenter>
                            <CircularProgress />
                        </APCenter>
                    </APExpanded> :
                    <APColumn crossAxisAlignment="start">
                        <APSizedBox height='16px' />
                        <APColumn style={{ padding: '16px' }} crossAxisAlignment='center' gap='32px'>
                            <APImage width={118} height={120} src='img/DigiLocker_info.svg' />
                            <APImage width={114.738} height={28.069} src='img/DigiLocker_Logo.svg' />
                            <APColumn crossAxisAlignment="start">
                                <APText style={APTypography.h4}>Please keep the following ready for KYC:</APText>
                                <APText style={APTypography["paragraph-small"]}>• Image of your signature</APText>
                                <APText style={APTypography["paragraph-small"]}>• Photograph of the applicant</APText>
                                <APText style={APTypography["paragraph-small"]}>• Phone number linked to Aadhaar</APText>
                                <APText style={APTypography["paragraph-small"]}>• Proof of Identity: PAN Image</APText>
                            </APColumn>
                            {((currentUser == 'primary' && statusesThatNeedsRefresh.includes(activationData.firstHolderDigiLockerWorkflowStatus ?? '')) || (currentUser == 'guardian' && statusesThatNeedsRefresh.includes(activationData.guardianDigiLockerWorkflowStatus ?? ''))) && (<APTag variant="positive">You have completed the process on DigiLocker. Click on Next to continue</APTag>)}
                            <APRow gap="4px">
                                <APImage width={16} height={16} src='icons/icon_safe.svg' />
                                <APText style={APTypography["paragraph-small"]}>Your data is 100% safe and secure</APText>
                            </APRow>
                        </APColumn>
                        <APSizedBox height='16px' />
                    </APColumn>
            ),
            onSubmit: isFetchingUpdate ? () => { throw new Error("Please Wait") } : async () => {
                await performDigiLockerProcess();

            }
        });
    };

    if (holdingType == 'AS' && !activationData.secondHolderHasCkyc) {
        steps.push({
            title: "DigiLocker for Holder 2",
            description: activationData.secondHolderName ?? '',
            code: "SH",
            form: (
                <APColumn crossAxisAlignment="start">
                    <APSizedBox height='16px' />

                    <APColumn style={{ padding: '16px' }} crossAxisAlignment='center' gap='8px'>
                        <APImage width={118} height={120} src='img/DigiLocker_info.svg' />
                        <APSizedBox height="32px" />
                        <APImage width={114.738} height={28.069} src='img/DigiLocker_Logo.svg' />
                        <APSizedBox height="32px" />
                        <APColumn crossAxisAlignment="start">
                            <APText style={APTypography.h4}>Please keep the following ready for KYC:</APText>
                            <APText style={APTypography["paragraph-small"]}>• Image of your signature</APText>
                            <APText style={APTypography["paragraph-small"]}>• Photograph of the applicant</APText>
                            <APText style={APTypography["paragraph-small"]}>• Phone number linked to Aadhaar</APText>
                            <APText style={APTypography["paragraph-small"]}>• Proof of Identity: PAN Image</APText>
                        </APColumn>
                        {statusesThatNeedsRefresh.includes(activationData.secondHolderDigiLockerWorkflowStatus ?? '') && (<APTag variant="positive">You have completed the process on DigiLocker. Click on Next to continue</APTag>)}
                        <APRow gap="4px">
                            <APImage width={16} height={16} src='icons/icon_safe.svg' />
                            <APText style={APTypography["paragraph-small"]}>Your data is 100% safe and secure</APText>
                        </APRow>
                    </APColumn>
                    <APSizedBox height='16px' />
                </APColumn>
            ),
            onSubmit: isFetchingUpdate ? () => { throw new Error("Please Wait") } : async () => {
                await performDigiLockerProcess();
            }
        });
    };

    return {
        code: "DigiLocker",
        title: "DigiLocker",
        description: activationData.name ?? '',
        steps
    };
};


export class PhotoRequestForHolderClass {
    token!: string;
    photoBase64!: string;
    holder!: 'primary' | 'secondary' | 'guardian'
}