import { Type } from "class-transformer"

export default class NomineeDeclarationModel {
    name!: string
    @Type(() => NomineeModel)
    nominee?: NomineeModel
    @Type(() => GuardianModel)
    guardian?: GuardianModel
}

export class NomineeModel {
    name?: string
    relationship?: string
    relationshipCode?: string
    status: string = "PENDING"
}

export class GuardianModel {
    name?: string
    relationshipCode?: string
    status: string = "PENDING"
}

export class NomineeApiReq {
    nominee?: string
    nomineeRelation?: string
    nomineeRelationCode?: string
    guardian?: string
    guardianRelationCode?: string
}